.partnership-programs-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.partnership-program {
    padding: 10px;
    background-color: $color_white;
    border-radius: 10px;
    border: 5px solid $color_blue_light;

    @include respond-to('tablet-h') {
        border: 10px solid $color_blue_light;
        border-radius: 15px;
        padding: 20px;
    }

    &> img {
        border-radius: 10px;
        aspect-ratio: 16/9;
        object-fit: cover;
        margin-bottom: 20px;
    }

    &__suptitle {
        font-weight: 800;
        line-height: 1.4;

        @include respond-to('tablet-h'){
            font-size: 18px;
        }
    }

    &__content {
        ul {
            list-style: initial;
            margin-left: 20px; 
            line-height: 1.5;   
        }
    }
}

.partnership-programs {
    background-color: $color_blue;
    border-radius: 20px;
    padding: 20px;

    @include respond-to('tablet-h') {
        padding: 40px;
    }

    &__content {
        max-width: 616px;
        margin-left: auto;
        margin-right: auto;
    }

    &__list {
        margin-top: 30px;
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            flex-direction: row;
            flex-wrap: wrap;

            .partnership-program {
                width: calc((100% - 20px) / 2)
            }
        }
    }
}