.generic-page-section {
    padding: 20px 0 50px;

}

.generic-page {
    gap: 30px;
    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        @include flex(row, space-between, stretch);
    }

    &__sidebar {
        @include respond-to('desktop') {
            min-width: 530px;
        }
    }

    &__content {

        @include respond-to('tablet-h') {
            max-width: 500px;
        }

        @include respond-to('laptop') {
            max-width: 660px;
        }
        
        h1, h2, h3, h4, h5, h6 {
            padding: 20px 0;

            @include respond-to('tablet-h') {
                padding: 24px 0;
            }
        }

        h1, .heading-1 {
            font-size: 36px;
            padding-top: 0;

            @include respond-to('tablet-h') {
                font-size: 42px;
            }
        }

        h2 {
            font-size: 32px;

            @include respond-to('tablet-h') {
                font-size: 36px;
            }
        }

        h3 {
            font-size: 28px;

            @include respond-to('tablet-h') {
                font-size: 32px;
            }
        }

        h4 {
            font-size: 24px;

            @include respond-to('tablet-h') {
                font-size: 28px;
            }
        }

        p {
            margin-bottom: 16px;
        }

        strong {
            font-weight: 700;
        }

        blockquote {
            margin: 20px 0 32px;
            padding-left: 24px;
            border-left: 2px solid $color_black;
            font-style: italic;
            font-size: 1rem;

            @include respond-to('tablet-h') {
                font-size: 1.25rem;
            }
        }

        ul, ol {
            padding-left: 20px;
            list-style: initial;
            line-height: 1.4;
        }

        img {
            border: 5px solid $color_white;
            border-radius: 15px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-width: 100%;
            height: auto;
            margin-top: 10px;
            margin-bottom: 10px;

            @include respond-to('tablet-h') {
                border: 10px solid $color_white;
                margin-top: 48px;
                margin-bottom: 48px;
            }
        }
        table {
            border-collapse: collapse;
            margin-bottom: 16px;

            td, th {
                border: 1px solid $color_black;
                padding: 8px;
            }
        }
    }
}