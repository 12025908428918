.camp-finder-section {
    padding: 32px 0 50px;
}

.camp-finder {
    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row, space-between, flex-start);
    }

    #camp-finder-js-noresults {
        &.hidden {
            display: none;
        }

        &:has(.view-on-map) {
            .camp-finder__communities {
                margin-top: 20px;
            }
        }

        &>.view-on-map {
            margin-top: 20px;

            .location-card {
                cursor: default;
            }
        }
    }

    &__content {
        margin-top: 30px;

        .camp-finder__map {
            margin-top: 30px;
            height: 390px;
            background-color: $color_white;
        }

        @include respond-to('tablet-h') {
            margin-top: 0;
            width: 490px;

            .camp-finder__map {
                display: none;
            }
        }

        @include respond-to('laptop') {
            width: 510px;
        }
    }

    &__map {
        border-radius: 10px;
        margin-left: -16px;
        margin-right: -16px;
        overflow: hidden;

        @include respond-to('tablet-h') {
            width: calc(100% - (490px + 40px));
            height: 600px;
            margin-left: 0;
            margin-right: 0;
        }

        @include respond-to('laptop') {
            width: calc(100% - (510px + 72px));
        }
    }

    &__form {
        margin-top: 30px;

        .form__field {
            justify-content: space-between;
        }

        .heading-4 {
            margin-bottom: 10px;
        }

        input {
            flex-grow: 1;
            width: 100%;
            margin-bottom: 1rem;

            @include respond-to('tablet-h') {
                width: auto;
            }
        }

        .btn {
            display: none;

            @include respond-to('tablet-h') {
                display: block;
            }
        }

        select {
            display: flex;
            height: 46px;
            padding: 10px 15px;
            border-radius: 5px;
            border: 2px solid #DBDBDB;


            @include respond-to('tablet-h') {
                margin-left: 20px;
            }
        }
    }

    &__count-results {
        width: 100%;
        margin-top: 24px;
        font-weight: 400;

        @include flex(row, space-between, center);
    }

    &__clear {
        text-decoration: underline;
        color: $color_black;
    }

    &__noresults {
        background-color: $color_yellow;
        border-radius: 15px;
        padding: 20px;
        min-height: 150px;
        gap: 12px;
        margin-top: 24px;

        @include flex(column, center, center);

        p {
            font-size: 1rem;
        }

        a {
            text-decoration: underline;
            font-size: 1.125rem;
            font-weight: 800;
            line-height: 1.4;
            color: $color_black;
        }

        &.hidden {
            display: none;
        }
    }

    &__communities {
        margin-top: 50px;

        &-title {
            font-size: 1.125rem;
            line-height: 1.4;
            margin-bottom: 20px;
        }
    }

    &__state {
        padding: 0;
        background-color: $color_beige;
        //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
        border-radius: 5px;

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }

        &-title {
            font-size: 1.125rem;
            line-height: 1.4;
            font-weight: 800;
            gap: 10px;
            background-color: $color_white;
            padding: 20px;

            @include flex(row, space-between, center);

            span {
                font-weight: 400;
                margin-right: auto;
                font-size: 0.9rem;
            }
        }

        &-list {
            background-color: $color_white;
            border-radius: 5px;

            &.is-open {
                margin-top: 8px;
            }

            @include flex(column, flex-start, stretch);

            li {
                padding: 1rem 20px;
                border-radius: 5px;
                color: $color_black;
                text-decoration: none;
                cursor: pointer;
                
                @include flex(row, space-between, center);

                &:hover {
                    background-color: $color_blue;
                }

                span {
                    pointer-events: none;
                    color: rgba($color_black, 0.30);
                }

                &.active {
                    border: 1px solid #000;
                    border-radius: 5px;
                    background-color: $color_blue;

                    span {
                        color: #000;
                    }
                }
            }
        }
    }

    &__cards {
        max-height: 500px;
        overflow-y: scroll;
        gap: 20px;
        margin-top: 50px;

        @include flex(column, flex-start, stretch);
    }

    .gm-style .gm-style-iw-c {
        max-height: fit-content !important;
    }

    .gm-style-iw-chr {
        max-height: 25px;
    }

    .gm-style-iw-d {
        max-height: fit-content !important;
    }

    &--regional {
        &__description {
            margin-top: 1.5rem;
        }

        .camp-finder__content {
            margin-top: 0;
        }

        @include respond-to('desktop') {
            margin-top: 4rem;
        }
    }
}


.map-content__url {
    color: #000;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 3.5;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    &:focus-visible {
        border: none;
        outline: none;
        text-decoration: underline;
    }

    &:hover {
        text-decoration: underline;
    }
}