.hero-video-section {
    padding: 30px 0;
    overflow: hidden;
    
    @include respond-to('tablet-h') {
        padding: 75px 0;
        margin-top: -35px;
    }
}

.hero-video {
    gap: 30px;

    @include flex(column-reverse, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row, space-between, center);
    }

    @include respond-to('desktop') {
        gap: 70px;
    }

    &__body {
        @include respond-to('tablet-h') {
            width: calc( 100% - (500px + 40px));
        }

        @include respond-to('laptop') {
            width: calc( 100% - (630px + 40px));
        }

        @include respond-to('desktop') {
            width: calc( 100% - (630px + 70px));
        }
    }

    &__media {
        @include respond-to('tablet-h') {
            width: 500px;
        }

        @include respond-to('laptop') {
            width: 630px;
        }
    }

    &__suptitle {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    &__title {
        line-height: 1.1;
        letter-spacing: -0.36px;

        span {
            line-height: 1.2;
            letter-spacing: -1.08px;
        }
    }

    &__content {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__frame {
        position: relative;

        .doodle--top-left {
            display: none;

            @include respond-to('tablet-h') {
                display: block;
                top: -62px;
                left: -55px;
            }
        }

        .doodle--top-right {
            width: 72px;
            height: 80px;
            top: -13px;
            right: -20px;

            @include respond-to('tablet-h') {
                display: none;
            }
        }
    }

    &__video, &__embed {
        width: 100%;
        border-radius: 15px;
        border: 5px solid $color_white;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;

        @include respond-to('tablet-h') {
            border: 10px solid $color_white;
            max-width: 840px;
        }
    }

    &__video {
        @include respond-to('phone') {
            height: 187px;
        }

        @include respond-to('tablet') {
            height: 400px;
        }

        @include respond-to('tablet-h') {
            height: 290px;
        }

        @include respond-to('laptop') {
            height: 363px;
        }
    }

    &__embed {
        iframe {
            width: 100%;
            
            @include respond-to('phone') {
                height: 187px;
            }

            @include respond-to('tablet') {
                height: 400px;
            }

            @include respond-to('tablet-h') {
                height: 268px;
            }

            @include respond-to('laptop') {
                height: 343px;
            }
        }
    }

}