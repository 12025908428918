.activity-card {
    text-decoration: none;
    color: $color_black;
    height: 100%;
    border: 3px solid $color_white;
    border-radius: 10px;

    @include flex(column, start, center);

    @include respond-to('tablet-h') {
        width: fit-content;
        min-width: 11.5rem;
        min-height: 145px;
    }

    &__title {
        font-family: $font_script;
        font-size: 16px;
        text-align: center;
        margin-bottom: 0.2rem;
    }

    &__thumbnail {
        width: 100%;
        height: 100px;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__status {
        gap: 5px;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.5;
        border: 1px solid $color_black;
        border-radius: 85px;
        padding: 5px 10px 5px 20px;
        background-color: $color_white;
        position: relative;
        display: inline-flex;
        align-items: center;
        text-align: center;
        margin: 10px 0;
        &::after {
            content: '';
            width: 12px;
            height: 12px;
            left: 7px;
            position: absolute;
        }

        &.waitlist {
            &::after {
                background-image: url("/img/status-waitlist.svg");
            }
        }

        &.open {
            &::after {
                background-image: url("/img/status-open.svg");
            }
        }

        &.few-left, &.almost-full, &.closed-for-enrollment {
            &::after {
                background-image: url("/img/status-few-left.svg");
            }
        }

    }

    &__ages {
        &.no-session {
            margin-top: 0.5rem;
            font-weight: 700;
            text-align: center;
        }
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
    }

    &__name {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;
    }

    &__price {
        font-size: 19px;
        font-weight: 700;
        margin: 5px 0;
        margin-top: auto;
    }

    &__more-info {
        font-size: 15px !important;
        font-weight: 500 !important;
        line-height: 1.4;
        text-decoration: underline;
        margin-bottom: 10px;

        @include respond-to('tablet-h') {
            margin-top: 5px;
        }
    }
}
