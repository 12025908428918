.camp-blog {
    &-swiper {
        margin-top: 30px;
        overflow: visible !important;

        .swiper-wrapper {
            @include respond-to('tablet') {
                justify-content: center;
            }
        }

        .swiper-slide {
            width: 280px;
            height: auto;

            @include respond-to('phone') {
                width: 320px;
            }

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }

        &__pagination {
            margin-top: 30px;
            text-align: center;

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }
}