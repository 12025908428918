.programs-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0 70px;
    }
}

.programs {
    gap: 15px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 40px;
    }

    &__items {
        gap: 10px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            display: grid;
            gap: 10px;
            align-items: stretch;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .program-card {
        border-radius: 18px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
        padding: 35px 25px;
        background-color: $color_white;
        position: relative;
        z-index: 2;
        
        &::before {
            content: '';
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 5px;
            right: 5px;
            border-radius: 15px;
            background-color: $color_yellow;
            z-index: -1;
        }

        &:nth-of-type(2n) {
            &::before {
                background-color: $color_green;
            }
            
        }
        &:nth-of-type(3n) {
            &::before {
                background-color: $color_blue;
            }
            
        }
        &:nth-of-type(4n) {
            &::before {
                background-color: $color_orange;
            }
        }

        @include respond-to('tablet-h') {
            border-radius: 23px;
            padding: 20px 30px 30px; 

            &::before {
                top: 10px;
                bottom: 10px;
                left: 10px;
                right: 10px;
            }
    
            &:nth-of-type(2n) {
                &::before {
                    background-color: $color_green;
                }
            }
            &:nth-of-type(3n) {
                &::before {
                    background-color: $color_blue;
                }
            }
            &:nth-of-type(4n) {
                &::before {
                    background-color: $color_orange;
                }
            }
        }

        &__container {
            gap: 20px;
            @include flex(column, stretch, flex-start);
            text-decoration: none;
            color: $color_black;
            height: 100%;
        }

        &__image {
            margin-left: -10px;
            margin-right: -10px;
            display: none;

            @include respond-to('tablet-h') {
                display: block;
            }

            &> img {
                width: 100%;
                height: 308px;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        &__tag {
            font-size: .9375rem;
            font-weight: 600;
            line-height: 1.5;
        }

        &__title {
            font-size: 1.125rem;
            font-weight: 800;
            line-height: 1.4;

            @include respond-to('tablet-h') {
                font-size: 1.5rem;
                line-height: 1.3;
            }
        }

        &__footer {
            flex-grow: 1;
            display: flex;
            align-items: flex-end;
        }
    }
}