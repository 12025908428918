.camp-role {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 16px;

    @include respond-to('tablet') {
        flex-direction: row;
        margin-bottom: 30px;
    }

    &__photo {
        img {
            width: 150px;
            height: 150px;
            object-fit: cover;
            border-radius: 190px;
            border: 5px solid $color_charcoal;

            @include respond-to('tablet-h') {
                width: 190px;
                height: 190px;
            }
        }
    }

    &__info {
        text-align: left;

        @include respond-to('tablet-h') {
            text-align: left;
        }
    }

    &__position,
    &__name {
        font-weight: 700;
        line-height: 1.4;
        
    }

    &__position {
        font-size: 18px;
        @include respond-to('tablet-h') {
            font-size: 20px;
        }
    }

    &__name {
        font-size: 24px;
        @include respond-to('tablet-h') {
            font-size: 36px;
        }
    }

    &__contact {

        list-style: none !important;
        padding-left: 0 !important;

        a {
            font-weight: 800;
            color: $color_blue;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none;
        }
    }
}