.partners-slider-section {
    padding: 50px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 70px 0;
    }
}

.partners-slider-swiper {
    margin-top: 20px;
    overflow: visible !important;

    @include respond-to('tablet-h') {
        margin-top: 30px;
    }

    @include respond-to('laptop') {
        overflow: hidden !important;
    }

    .swiper-slide {
        width: fit-content;
        text-align: center;
    }

    a {
        display: block;
    }

    &__pagination {
        margin-top: 30px;
        text-align: center;

        .swiper-pagination-bullet-active {
            background-color: $color_black;
        }
    }
}

.partners-slider {
    &__logo {
        height: 75px;
        max-width: 200px;
        object-fit: contain;

        @include respond-to('tablet-h') {
            height: 75px;
            width: auto;
        }
    }
}