.image-slider-section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.image-slider__image {
    max-width: 304px;

    img {
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 1;

        @include respond-to('tablet-h') {
            border-radius: 15px;
            border: 10px solid $color_white;
            // box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        }
    }
}

.image-slider {
    &-swiper {
        overflow: visible !important;
        @include respond-to('tablet-h') {
            overflow: hidden !important;
        }

        &__controls {
            margin-top: 20px;

            @include respond-to('tablet-h') {
                margin-top: 48px;

                @include flex(row, space-between, center);
            }
        }

        &__button-prev,
        &__button-next {
            z-index: 100;
            display: none;

            @include respond-to('tablet-h') {
                display: flex;
            }
        }

        &__button-prev {
            transform: rotate(180deg);
        }

        &__pagination {
            text-align: center;

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }
}