.doodle {
    position: absolute;
    object-fit: contain;

    &--top-left {
        top: 0;
        left: 0;
    }

    &--top-right {
        top: 0;
        right: 0;
    }

    &--bottom-right {
        bottom: 0;
        right: 0;
    }

    &--bottom-left {
        bottom: 0;
        left: 0;
    }
}