.image-content-medium-section {
    padding: 50px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.image-content-medium {
    gap: 20px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 70px;

        @include flex(row-reverse, space-between, center);
    }

    &__media {
        @include respond-to('tablet-h') {
            width: 494px;
        }

        @include respond-to('laptop') {
            width: 750px;
        }
    }

    &__body {
        @include respond-to('tablet-h') {
            width: calc( 100% - (494px + 70px));
        }

        @include respond-to('laptop') {
            width: calc( 100% - (750px + 70px));
        }
    }

    &__image {
        position: relative;

        &> img {
            object-fit: cover;
            aspect-ratio: 21 / 11;
            max-height: 358px;
        }
    }
}