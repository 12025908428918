.camp-main-section {
    padding: 30px 0;
    overflow: hidden;
    
    @include respond-to('tablet-h') {
        padding: 75px 0;
        margin-top: -35px;
    }
}

.camp-main {

    @include flex(column, flex-start, flex-start);

    @include respond-to('tablet-h') {
        @include flex(row, space-between, center);
        column-gap: 2rem;
    }

    @include respond-to('desktop') {
        gap: 80px;
    }

    &__info {
        margin-bottom: 50px;

        @include flex(column, flex-start, flex-start);

        &> *:not(:last-child) {
            margin-bottom: 20px;
        }

        @include respond-to('tablet-h') {
            margin-bottom: 0;
        }
    }

    &__meta {
        li {
            font-size: 1rem;
            font-weight: 700;
            margin-top: 10px;

            @include flex(row, flex-start, center);

            svg{
                min-width: 32px;
                max-width: 32px;
            }
        }
    }

    &__content {
        font-size: 1rem;

        @include flex(column, flex-start, flex-start);
    }

    &__media {
        width: 100%;

        @include respond-to('tablet-h') {
            max-width: 500px;
        }

        @include respond-to('laptop') {
            max-width: 700px;
        }
    }

    &__swiper {
        overflow: visible !important;

        .swiper-wrapper {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        }

        .swiper-slide {
            width: 100% !important;
            
            .image-rounded {
                box-shadow: none;
            }
        }

        &-pagination {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 10px;

            @include respond-to('tablet-h') {
                display: none;
            }

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }
}