.locations {
    padding: 50px 0;
    text-align: center;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }

    &__grid {
        gap: 20px;
        margin: 20px 0;
        flex-wrap: wrap;

        @include flex(row, center, center);

        @include respond-to('tablet-h') {
            gap: 15px;
            margin: 30px 0;
        }
    }

    &__content {
        font-weight: 500;
    }

    &__item {
        img {
            width: 101px;
            height: 77.9px;
            object-fit: cover;

            @include respond-to('tablet') {
                width: 125px;
                height: auto;
            }

            @include respond-to('tablet-h') {
                width: 170px;
            }

            @include respond-to('laptop') {
                width: 200px;
            }

            @include respond-to('desktop') {
                width: 244px;
            }
        }
    }

    &-swiper {
        display: none;
        position: relative;
        margin: 20px auto;
        img {
            width: 100%;
            max-width: 130px;
            height: auto;
            object-fit: cover;
        }

        &__pagination{
            margin-top: 16px;

            span.swiper-pagination-bullet-active {
                background-color: #0C161F;
            }
        }

        &__button-prev,
        &__button-next {
            position: absolute;
            top: 20%;
            z-index: 10;
            background: white;
            border-radius: 50%;
            cursor: pointer;

            span {
                border: 0;
                width: 40px;
                height: 40px;
            }
        }

        &__button-prev {
            left:5px;
            transform: rotate(180deg);
            box-shadow: 2px -3px 0 0 #0C161F;
        }

        &__button-next {
            right: 5px;
            box-shadow: 2px 3px 0 0 #0C161F;
        }

        .swiper-slide-fully-visible {
            display: flex;
            justify-content: flex-end;
        }
        .swiper-slide-fully-visible + .swiper-slide {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__ab-test .ab-test-on {
        display: none;

        @include respond-to('tablet') {
            display: flex;
        }
    }

    &__ab-test .locations-swiper {
        display: block;

        @include respond-to('tablet') {
            display: none;
        }
    }

    &__ab-test .locations__content {
        width: 300px;
        text-align: center;
        margin: 10px auto;

        @include respond-to('tablet') {
            width: 100%;
            margin: 10px auto 0;
        }
    }
}