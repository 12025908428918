.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 46px;
    padding: 12px 16px;
    border: 2px solid $color_black;
    color: $color_black;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    border-radius: 100px;
    box-shadow: 2px 3px 0 0 $color_black;
    transition: box-shadow .2s ease-in-out;
    background-color: $color_white;
    cursor: pointer;

    @include respond-to('phone') {
        padding: 12px 25px; 
    }

    &:hover {
        box-shadow: 0 0 0 0 $color_black;
    }

    &--blue {
        background-color: $color_blue;
    }

    &--yellow {
        background-color: $color_yellow;
    }

    &--semi-rounded {
        justify-content: space-between;
        min-height: 52px;
        padding: 15px 20px;
        border-radius: 10px;

        @include respond-to('tablet-h') {
            justify-content: center;
        }

        svg {
            margin-left: 10px;
            transition: all .2s ease-in;
        }
    }

    &--black {
        padding: 24px 20px;
        justify-content: space-between;
        border-radius: 10px;
        border: 5px solid $color_white;
        background-color: $color_charcoal;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        font-size: 1rem;
        font-weight: 700;
        color: $color_white;
        line-height: 1.4;
        
        justify-content: space-between;
        gap: 20px;

        &:hover {
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
        }

        svg {
            min-width: 18px;
        }

        @include respond-to('desktop') {
            padding: 32px 20px;
            font-size: 1.25rem;
        }
    }

    &--simple {
        position: relative;
        min-height: auto;
        padding: 0 18px 0 0;
        border: none;
        box-shadow: none;
        background-color: transparent;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 12.5px;
            height: 22px;
            background-image: url("/img/arrow-right.svg");
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &--block {
        display: flex;
        width: 100%;
    }

    &--medium {
        max-width: 340px;
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 5px solid $color_black;
    border-radius: 100%;

    &--prev {
        transform: rotate(180deg);
    }
}