.blog-cards-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.blog-card {
    border: 5px solid $color_blue;
    border-radius: 20px;
    padding: 15px 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    gap: 20px;
    background-color: $color_white;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
       padding: 10px;

       width: calc( (100% - (22px * 2)) / 3);
    }

    &:nth-of-type(2n) {
        border-color: $color_green;
    }

    &:nth-of-type(3n) {
        border-color: $color_pink;
    }

    &:nth-of-type(4n) {
        border-color: $color_orange;
    }

    &:nth-of-type(5n) {
        border-color: $color_yellow;
    }

    &__header {
        display: none;

        @include respond-to('tablet-h'){
            display: flex;
            img {
                height: 300px;
                object-fit: cover;
                border-radius: 15px;
            }
        }
    }

    &__body {
        padding: 0 10px 0;
    }

    &__meta {
        margin-bottom: 10px;
        gap: 10px;
        font-weight: 600;
        line-height: 1.5;
        flex-wrap: wrap; 
        @include flex(row, flex-start, center);
    }

    &__category {
        padding: 6px 12px;
        border-radius: 200px;
        border: 1px solid $color_black;
        text-decoration: none;
        color: $color_black;
        font-size: 12px;
        line-height: 1.5;
    }

    &__reading {
        font-size: 14px;
        line-height: 1.5;
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 8px;

        @include respond-to('tablet-h') {
            font-size: 24px;
        }
        a {
            text-decoration: none;
            color: $color_black;
        }
    }

    &__foot {
        padding: 0 10px 10px;
        flex-grow: 1;

        display: flex;
        align-items: flex-end;
    }
}

.blog-cards {
    &__header {
        margin-bottom: 30px;
        text-align: center;

        @include respond-to('tablet-h') {
            @include flex(row, space-between, center);
        }
    }

    &__list {
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            gap: 22px;

            @include flex(row, flex-start, stretch);
        }
    }

    &__footer {
        margin-top: 30px;
        text-align: center;
    }
}