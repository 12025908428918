.camp-pricing-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0;
    }
}

.camp-pricing {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: stretch;
}