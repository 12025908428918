.posts-filter {
    @include flex(column, center, center);
    flex-wrap: wrap;
    gap: 20px;

    @include respond-to('tablet-h') {
        @include flex(row, space-between, center);
    }

    &__categories {
        gap: 10px;
        flex-wrap: wrap;

        @include flex(row, flex-start, center);
    }

    &__category {
        label {
            display: flex;
            padding: 8px 18px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 100px;
            border: 2px solid $color_charcoal;
            font-weight: 700;
            transition: all .2s ease-in-out;
            line-height: 1.4;
            cursor: pointer;

            &:hover {
                background-color: $color_charcoal;
                color: $color_white;
            }
        }

        input {
            display: none;

            &:checked + label {
                background-color: $color_charcoal;
                color: $color_white;
            }
        }
    }

    &__search {
        gap: 10px;
        flex-wrap: wrap;

        @include flex(row, center, stretch);

        @include respond-to('tablet-h') {
            @include flex(row, flex-end, center);
        }
    }
}