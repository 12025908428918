.tabs-faq-section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 80px 0;
    }
}

.tabs-faq {

    @include respond-to('tablet-h') {
        gap: 80px;
        @include flex(row, space-between, flex-start);
    }

    &__left {
        &> .on-desktop {
            margin-top: 32px;
        }
    }

    &__right {
        @include respond-to('tablet-h') {
            max-width: 500px;
        }

        @include respond-to('laptop') {
            max-width: 720px;
        }
    }

    &__buttons {
        margin-top: 30px;
        margin-bottom: 30px;
        gap: 10px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;

        @include flex(row, flex-start, center);

        @include respond-to('tablet-h') {
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 30px;
        }
    }

    &__button {
        padding: 8px 18px;
        border-radius: 100px;
        border: 3px solid $color_blue;
        min-width: fit-content;
        cursor: pointer;
        transition: all .2s ease-in-out;

        @include flex(row, center, center);

        &:hover, &.active {
            background-color: $color_blue;
            font-weight: 700;
        }

        &:nth-of-type(2n) {
            border-color: $color_green;
            &:hover, &.active {
                background-color: $color_green;
            }
        }
        
        &:nth-of-type(3n) {
            border-color: $color_yellow;
            &:hover, &.active {
                background-color: $color_yellow;
            }
        }
    }

    &__tabs {
        position: relative;
        margin-bottom: 30px;

        @include respond-to('tablet-h') {
            margin-bottom: 0;
        }
    }

    &__items {
        gap: 10px;
        top: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        height: 0;
        width: 100%;
        // overflow: hidden;

        @include flex(column, flex-start, stretch);

        &[data-show="true"] {
            position: relative;
            z-index: 2;
            opacity: 1;
            visibility: visible;
            height: auto;
        }
    }

    &__item {
        background-color: $color_charcoal;
        color: $color_white;
        padding: 20px;
        border-radius: 15px;
        border: 5px solid $color_white;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.4;

        &-title {
            gap: 10px;

            @include flex(row, space-between, center);

            @include respond-to('tablet-h') {
                font-size: 1.125rem;
            }

            svg {
                min-width: 24px;
                path {
                    fill: $color_white;
                }
            }
        }

        &-content {
            font-weight: 500;
            margin-top: 20px;
            a {
                color: $color_white;
            }
        }
    }
}