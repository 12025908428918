.image-content-color-section {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.image-content-color {
    padding: 20px;
    gap: 20px;
    border-radius: 20px;

    @include flex(column, flex-start, flex-start);

    @include respond-to('tablet-h') {
        padding: 50px;
        gap: 80px;

        @include flex(row, space-between, center);

        &--base {
            background-color: $color_beige;
        }
    }

    &__suptitle {
        font-size: 15px;
        font-weight: 700;

        @include respond-to('tablet-h'){
            font-size: 20px;
        }
    }

    &__title {
        font-size: 36px;

        @include respond-to('tablet-h'){
            font-size: 48px;
            margin-top: 20px;
        }
    }

    &__content {
        @include respond-to('tablet-h'){
            margin-top: 10px;
        }
    }

    .btn {
        @include respond-to('tablet-h'){
            margin-top: 20px;
        }
    }

    &__body {
        gap: 10px;

        @include flex(column, flex-start, flex-start);

        @include respond-to('tablet-h') {
            gap: 0;
        }
    }

    &__media {
        @include respond-to('tablet-h') {
            min-width: 470px;
        }

        @include respond-to('desktop') {
            min-width: 560px;
        }
    }

    &--yellow {
        background-color: $color_yellow;
    }
    &--green {
        background-color: $color_green;
    }
    &--blue {
        background-color: $color_blue;
    }
    &--pink {
        background-color: $color_pink;
    }
    &--orange {
        background-color: $color_orange;
    }

    &--right {
        @include respond-to('tablet-h') {
            @include flex(row-reverse, space-between, center);
        }
    }

    &--charcoal {
        background-color: $color_charcoal;
        color: $color_white;
    }

}