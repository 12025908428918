body {
    &> img {
        display: none;
    }

    &.sticky-header {

        main {
            padding-top: 60px;

            @include respond-to('tablet-h') {
                padding-top: 132px;
            }
        }
    }
}

.header {
    background-color: $color_beige;
    transition: all .2s ease-in-out;

    .container {
        padding-top: 10px;
        padding-bottom: 10px;
        
        @include flex(row, space-between, center);

        @include respond-to('tablet-h') {
            padding-top: 20px;
            padding-bottom: 10px;
        }
    }

    &__logo {
        width: 121px;
        height: auto;
        display: block;
        margin: -10.38px -10px;

        @include respond-to('tablet-h') {
            width: 190.38px;
        }
    }

    &-nav {
        &-desktop {
            width: 100%;

            .top-menu,
            .main-menu {
                @include flex(row, flex-end, center);

                a {
                    text-decoration: none;
                    color: $color_black;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .top-menu {
                gap: 16px;
                margin-bottom: 24px;
                a {
                    font-size: 14px;
                    font-weight: 600;
                    display: inline-flex;
                    height: 21px;
                    align-items: center;
                }
            }

            .main-menu {
                gap: 12px;

                @include respond-to('laptop') {
                    gap: 32px;
                    &__items-container {
                        width: 65%;
            
                        @include flex(row, center, center);
                    }
                }

                a {
                    font-size: 12px;
                    font-weight: 700;
                    
                    @include flex(row, flex-start, center);

                    @include respond-to('laptop') {
                        font-size: 14px;
                    }

                    @include respond-to('desktop') {
                        font-size: 16px;
                    }

                    svg {
                        margin-left: 4px;
                    }

                    &.btn:hover {
                        text-decoration: none;
                    }
                }

                &> li {
                    position: relative;

                    @include respond-to('laptop') {
                        &.button {
                            margin-right: -16px;
    
                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }

                    &> ul {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%);
                        backface-visibility: hidden;
                        -webkit-backface-visibility: hidden;
                        perspective: 1000;
                        -webkit-perspective: 1000;
                        background-color: transparent;
                        padding-top: 10px;
                        display: none;
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity .2s ease-in;
                        z-index: 999;

                        &> li {
                            background-color: $color_beige;
                            padding: .8rem 1rem;

                            &:last-of-type {
                                border-bottom-left-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }

                    &:hover {
                        &> ul {
                            opacity: 1;
                            visibility: visible; 
                            display: block;
                        }
                    }
                }
            }
        }

        &-mobile {
            gap: 14px;

            @include flex(row, center, center);

            .btn {
                min-height: 36px;
                padding: 8px 20px;
                font-size: .875rem;
            }
        }
    }

    &-search-button {
        background-color: transparent;
        width: 24px;
        height: 32px;
        border: none;
        padding: 0;
        
        @include flex(row, center, center);
    }

    &-topbar {
        background-color: $color_blue;
        position: relative;
        z-index: 1;
        overflow: hidden;
        height: 40px;
        font-size: .875rem;

        &__slider {
            --gap: 40px;
            --duration: 60s;
            --duration: 125s;
            --scroll-start: 0;
            --scroll-end: calc(-100% - var(--gap));
            display: flex;
            overflow: hidden;
            user-select: none;
            gap: var(--gap);
            height: 40px;

            @include respond-to('tablet-h') {
                --gap: 600px;
            }
        }

        &__group {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: var(--gap);
            min-width: 100%;
            animation: scroll-x var(--duration) linear infinite;
        }

        @keyframes scroll-x {
            from {
                transform: translateX(var(--scroll-start));
            }
            to {
                transform: translateX(var(--scroll-end));
            }
        }
    }

    &.sticky {
        position: fixed;
        z-index: 999;
        width: 100%;

        .menu-mobile {
            top: 60px;
            height: calc( 100vh - 60px);
            overflow-y: hidden;

            &__top-bar-active {
                top: 6rem;
                height: calc( 100vh - 6rem);
            }
        }
    }
}