.testimonials-section {
    padding: 50px 0;
    overflow: hidden;
    @include respond-to('tablet-h') {
        padding: 100px 0;
    }

    @include respond-to('desktop') {
        padding: 175px 0 130px;
    }
}
.testimonials {
    @include respond-to('tablet-h'){
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    @include respond-to('desktop'){
        max-width: 1090px;
    }

    &__header {
        gap: 30px;
        margin-bottom: 46px;

        @include flex(row, center, center);

        img {
            display: block;
            width: 54.84px;
            height: 50px;
            object-fit: contain;

            @include respond-to('desktop') {
                width: 82.25px;
                height: 75px;
            }
        }

        @include respond-to('tablet-h') {
            justify-content: flex-start;
            margin-bottom: -50px;

            &--left {
                justify-content: flex-end;
            }
        }

        @include respond-to('desktop') {
            margin-bottom: -100px;
        }
    }

    &__title {
        font-size: 1.75rem;
        line-height: 1.2;
        color: $color_blue;
        
        @include respond-to('tablet-h') {
            font-size: 2.5rem;
            line-height: 1;
            text-align: center;
            max-width: 340px;
        }

        @include respond-to('desktop') {
            font-size: 3rem;
            max-width: 500px;
        }
    }
}

.testimonials-swiper {
    overflow: visible !important;

    .swiper-slide {
        &:not(:nth-child(1)) {
            transition: all .2s ease-in-out !important;
            opacity: 0 !important;

            &.swiper-slide-visible {
                opacity: 1 !important;
            }
        }
    }

    &__button-prev,
    &__button-next {
        display: none;
        z-index: 100;

        @include respond-to('tablet-h') {
            display: flex;
            position: absolute;
            bottom: -24px;
        }

        @include respond-to('desktop') {
            bottom: 40px;
        }
    }

    &__button-prev {
        left: 0;
        transform: rotate(180deg);
    }

    &__button-next {
        left: 198px;
    }

    &__pagination {
        margin-top: 15px;
        text-align: center;

        @include respond-to('tablet-h') {
            z-index: 100;
            height: 50px;
            position: absolute;
            left: 48px !important;
            width: 148px !important;
            bottom: -24px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include respond-to('desktop') {
            bottom: 40px !important;
        }

        .swiper-pagination-bullet-active {
            background-color: $color_black;
        }
    }

    @include respond-to('tablet-h') {
        &--left {
            .testimonials-swiper__button-prev {
                left: unset;
                right: 196px;
            }

            .testimonials-swiper__button-next {
                left: unset;
                right: 0px;
            }

            .testimonials-swiper__pagination {
                left: unset !important;
                right: 48px !important;
            }
        }
    }
}

.testimonial {
    @include flex(column, flex-start, center);

    @include respond-to('tablet-h') {
        @include flex(row, space-between, center);
    }

    &__photo {
        background-color: $color_white;
        padding: 16px 16px 0;
        width: 300px;
        height: 400px;
        filter: drop-shadow(5.793px 5.793px 8.689px rgba(0, 0, 0, 0.15));
        transform: rotate(8deg);

        img {
            width: 100%;
            height: 330px;
            object-fit: cover;
            object-position: center;

            @include respond-to('desktop'){
                height: 492px;
            }
        }

        figcaption {
            font-family: $font_script;
            font-size: 18px;
            line-height: 1.4;
            height: 50px;

            @include flex(row, center, center);

            @include respond-to('desktop') {
                font-size: 24px;
                line-height: 1.2;
                height: 84px;
            }
        }

        @include respond-to('desktop'){
            padding: 24px 24px 0;
            width: 460px;
            height: 600px
        }
    }

    &__message {
        padding: 23px 28px;
        max-width: 340px;
        min-width: 340px;
        background-color: $color_charcoal;
        color: $color_white;
        line-height: 1.4;
        border-radius: 20px;
        border: 5px solid $color_white;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.10));
        position: relative;
        margin-top: 5px;

        @include respond-to('tablet-h') {
            max-width: 449px;
            max-height: 364px;
            padding: 43px 40px;
        }

        @include respond-to('desktop') {
            max-width: 628px;
            min-width: 628px;
            font-size: 1.5rem;

            p {
                font-size: inherit;
            }
        }

        &::after {
            content: '';
            width: 27px;
            height: 28px;
            position: absolute;
            top: -28px;
            right: 80px;
            background-image: url("/img/caret.png");
            display: none;
        }
    }

    &__subtext {
        font-size: 12px;
        font-weight: 700;
        margin-top: 10px;
        margin-right: 10px;
        text-align: right;

        @include respond-to('tablet-h') {
            font-size: 14px;
        }

        @include respond-to('desktop') {
            font-size: 1rem;
        }
    }


    @include respond-to('tablet-h') {
        &--right {
            flex-direction: row-reverse;

            .testimonial__subtext {
                text-align: left;
                margin-right: 0;
                margin-left: 10px;
            }
        }

        &--left {
            .testimonial__photo {
                transform: rotate(-8deg);
            }
        }
    }
}