.footer-camps {
    padding: 30px 0;

    &__container {
        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            @include flex(row, center, stretch);
        }
    }

    &__location {
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        @include respond-to('tablet-h') {
            width: calc( (100% - (20px * 3)) / 4 );
            min-height: 70px;

            &:not(:last-of-type) {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        
    }

    .btn.is-open {
        svg {
            transform: rotate(180deg);
        }
    }

    .btn--block {
        height: 100%;
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 300px;
        overflow-y: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        background-color: $color_white;
        border-radius: 10px;
        padding: 10px 16px;
        border: 2px solid $color_black;
        transition: all .2s ease-in;

        opacity: 0;
        visibility: hidden;
        z-index: 2;

        &.is-open {
            opacity: 1;
            visibility: visible;
        }

        a {
            padding: 6px 0;
            display: inline-flex;
            text-decoration: none;
            color: $color_black;
            line-height: 1.4;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
