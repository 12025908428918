.camp-scholarships {
    background-color: $color_green;
    display: flex;
    padding: 30px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 15px;
    border: 1px solid $color_gray;

    @include respond-to('tablet-h') {
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 50px;
    }

    &__left {
        @include respond-to('tablet-h') {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
        } 
    }

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        text-align: center;

        @include respond-to('tablet-h'){
            text-align: left;
        }

        @include respond-to('laptop'){
            font-size: 36px;
            font-weight: 800;
            line-height: 1.1;
        }
    }

    &__icon {
        display: none;
        @include respond-to('tablet-h'){
            display: block;
        }
    }

    .btn {
        width: max-content;
    }
}