.camp-map-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0;
    }

    .single-map {
        height: 100%;
    }
}

.camp-map {
    border-radius: 10px;
    height: 390px;

    @include respond-to('tablet-h') {
        border-radius: 15px;
        border: 15px solid $color_white;
        height: 593px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    }
}