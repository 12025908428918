.separator {
    display: none;

    @include respond-to('tablet-h') {
        display: block;
        height: 90px;
        background-position-x: center;
        background-position-y: top;
        background-size: cover;
    }

    &--top {
        background-image: url("/img/separator-top.svg");
    }

    &--bottom {
        background-image: url("/img/separator-bottom.svg");
    }
}