.view-on-map.active {
    border: 2px solid $color_black;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);

    .location-card {
        background-color: #39b7ea;
    }
}

.location-card {
    gap: 15px;
    cursor: pointer;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
    background-color: $color_white;
    color: $color_black;

    @include flex(column, flex-start, stretch);

    &__top {
        @include respond-to('laptop') {
            @include flex(row-reverse, space-between, stretch);
        }
    }

    &__info {
        @include respond-to('laptop') {
            // border-right: 1px solid #DBDBDB;
            padding-right: 40px;
            flex-grow: 1;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    &__link {
        display: flex;
        gap: 5px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
        text-decoration: none;
        flex-direction: row;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }

        svg {
            width: 25px;
            height: 25px;
        }
    }

    &__tags {
        gap: 15px;
        margin-bottom: 15px;

        @include flex(row, flex-start, center);

        @include respond-to('laptop') {
            padding-left: 40px;
            margin-bottom: 0;

            @include flex(column, flex-start, flex-start);
        }
    }

    &__ages,
    &__dates {
        padding: 5px 15px;
        border-radius: 100px;
        font-weight: 600;
    }

    &__ages {
        color: $color_white;
        background-color: $color_charcoal;
        line-height: 1.5;
    }

    &__dates {
        border: 1px solid $color_black;
        background-color: $color_beige_light;
        line-height: 1.4;
    }

    &__title {
        font-size: 18px;
        font-weight: 800;
        line-height: 1.4;
        margin-bottom: 3px;
    }

    &__school {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
    }

    &__place {
        display: block;
        margin-top: 3px;
        font-size: 12px;
        font-weight: 400;
    }

}