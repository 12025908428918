.pagination {
    display: flex;
    justify-content: center;
    gap: 23px;

    &__pages {
        display: flex;
        justify-self: center;
        gap: 23px;
        a,
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 44px;
            border: 2px solid transparent;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            transition: all .2s ease-in-out;
            text-decoration: none;
            color: $color_black;
            opacity: .3;

            &.current,
            &:hover {
                border-bottom: 2px solid $color_black;
                opacity: 1;
            }
        }
    }

    &__next,
    &__prev {
        &.inactive {
            visibility: hidden;
        }

        a {
            width: 30px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 2px solid transparent;

            &:hover{
                border-bottom: 2px solid $color_black;
            }
        }
    }

    &__prev {
        transform: rotate(-180deg);
    }
}