.resource-center {  
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }

    .container {
        gap: 30px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            gap: 50px;
        }
    }

    .posts-grid {
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet') {
            
            flex-wrap: wrap;
            @include flex(row, flex-start, stretch);

            .blog-card {
                width: calc((100% - 20px)/2)
            }
        }

        @include respond-to('laptop') {
            .blog-card {
                width: calc((100% - (20px * 2))/3)
            }
        }

        @include respond-to('desktop') {
            gap: 64px 22px;
            .blog-card {
                width: calc((100% - (22px * 2))/3)
            }
        }

        &__title {
            font-size: 2rem;
            margin-bottom: 40px;
        }
    }
}