.image-content-steps-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0;
    }
}

.image-content-step {
    gap: 10px;

    @include flex(row, flex-start, flex-start);

    &__icon {
        min-width: 35px;

        img {
            width: 35px;
            height: 35px;
            object-fit: contain;
        }
    }

    &__content {
        &> .btn {
            margin-top: 20px;
        }

        p {
            margin-bottom: 10px;
        }
    }
}

.image-content-steps {

    @include respond-to('tablet-h') {
        gap: 60px;

        @include flex(row, space-between, center);
    }

    @include respond-to('laptop') {
        gap: 120px;
    }

    &__list {
        margin-top: 30px;
        margin-bottom: 30px;
        gap: 30px;

        @include flex(column, flex-start, stretch);
    }

    &__media {
        img {
            aspect-ratio: 1;
            object-fit: cover;
        }

        @include respond-to('tablet-h') {
            min-width: 570px;
        }

        @include respond-to('laptop') {
            min-width: 575px;

            img {
                aspect-ratio: unset;
                height: 514px;
            }
        }
    }
}

