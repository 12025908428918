.slider-cards-section {
    padding: 50px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.slider-cards {
    &-swiper {
        margin-top: 30px;
        overflow: visible !important;

        @include respond-to('tablet-h') {
            margin-top: 50px;
        }

        .swiper-wrapper {
            align-items: stretch !important;
        }

        .swiper-slide {
            max-width: 340px;
            height: auto;

            @include respond-to('tablet-h') {
                max-width: unset;
            }
        }

        &__pagination {
            text-align: center;
            margin-top: 30px;

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }

    &__title, &__content {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}

.slider-card {
    border-radius: 18px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    padding: 15px 25px 25px;
    background-color: $color_white;
    position: relative;
    z-index: 2;
    gap: 20px;
    height: 100%;
    text-decoration: none;
    color: $color_black;

    @include flex(column, stretch, flex-start);

    @include respond-to('tablet-h') {
        padding: 25px 35px 35px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
        border-radius: 15px;
        background-color: $color_pink;
        z-index: -1;

        @include respond-to('tablet-h') {
            top: 10px;
            bottom: 10px;
            left: 10px;
            right: 10px;
        }
    }

    &--green {
        &::before {
            background-color: $color_green;
        }
    }
    &--orange {
        &::before {
            background-color: $color_orange;
        }
    }
    &--blue {
        &::before {
            background-color: $color_blue;
        }
    }
    &--yellow {
        &::before {
            background-color: $color_yellow;
        }
    }

    &__image {
        margin-left: -10px;
        margin-right: -10px;

        @include respond-to('tablet-h') {
            max-width: unset;
        }

        &> img {
            width: 100%;
            height: 240px;
            object-fit: cover;
            border-radius: 10px;

            @include respond-to('tablet-h') {
                height: 308px;
            }
        }
    }

    &__title {
        margin-bottom: 5px;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.4;

        @include respond-to('tablet-h') {
            font-size: 1.5rem;
        }

    }

    &__footer {
        flex-grow: 1;
        display: flex;
        align-items: flex-end;
    }
}