.camp-image-content {
    padding: 20px;
    gap: 20px;
    border-radius: 20px;
    background-color: $color_charcoal;
    color: $color_white;

    @include flex(column, flex-start, flex-start);

    @include respond-to('tablet-h') {
        padding: 50px;
        gap: 80px;

        @include flex(row, space-between, flex-start);

    }


    &__title {
        font-size: 24px;
        margin-bottom: 10px;

        @include respond-to('tablet-h'){
            font-size: 36px;
        }
    }

    &__content {
        p {
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }
    }

    .btn {
        @include respond-to('tablet-h'){
            margin-top: 20px;
        }
    }

    &__body {
        gap: 10px;

        @include flex(column, flex-start, flex-start);

        @include respond-to('tablet-h') {
            gap: 0;
        }
    }

    &__media {
        @include respond-to('tablet-h') {
            min-width: 470px;
        }

        @include respond-to('desktop') {
            min-width: 560px;
        }
    }

    &__items {
        gap: 20px;
        margin-top: 20px;
        width: 100%;

        @include flex(column, flex-start, stretch);
    }

    &__item {
        border-left: 5px solid $color_white;
        padding: 20px;

        &-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 1.4;
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
                min-width: 12px;

                path {
                    fill: $color_white;
                }
            }
        }

        &-content {
            padding-top: 10px;
        }
    }
}