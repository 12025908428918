.tabs-projects-section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.project-card {
    padding: 10px;
    background-color: $color_white;
    border-radius: 10px;
    border: 5px solid $color_blue_light;

    @include respond-to('tablet-h') {
        border: 10px solid $color_blue_light;
        border-radius: 15px;
        padding: 15px;

        h4 {
            font-size: 24px;
            line-height: 1.4;
        }
    }

    &> img {
        border-radius: 10px;
        height: 220px;
        object-fit: cover;
        margin-bottom: 20px;
    }

}

.tabs-projects {

    &__header {
        @include respond-to('tablet-h'){
            @include flex(row, space-between, flex-end);


            
        }
    }
    &__buttons {
        margin-top: 30px;
        margin-bottom: 30px;
        gap: 10px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;

        @include flex(row, flex-start, center);

        @include respond-to('tablet-h') {
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            min-width: 480px;
            justify-content: flex-end;
        }
    }

    &__button {
        padding: 8px 18px;
        border-radius: 100px;
        border: 3px solid $color_blue;
        min-width: fit-content;
        cursor: pointer;
        transition: all .2s ease-in-out;

        @include flex(row, center, center);

        &:hover, &.active {
            background-color: $color_blue;
            font-weight: 700;
        }

        &:nth-of-type(2n) {
            border-color: $color_green;
            &:hover, &.active {
                background-color: $color_green;
            }
        }
        
        &:nth-of-type(3n) {
            border-color: $color_yellow;
            &:hover, &.active {
                background-color: $color_yellow;
            }
        }

        &:nth-of-type(4n) {
            border-color: $color_orange;
            &:hover, &.active {
                background-color: $color_orange;
            }
        }
    }

    &__body {
        position: relative;
    }

    &__grid {
        padding: 20px;
        gap: 20px;
        flex-wrap: wrap;
        background-color: $color_blue;
        border-radius: 15px;
        top: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        height: 0;
        width: 100%;

        @include flex(row, flex-start, stretch);

        &[data-show="true"] {
            position: relative;
            z-index: 2;
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        &:nth-of-type(2n) {
            background-color: $color_green;

            .project-card {
                border-color: $color_green_light;
            }
        }
        
        &:nth-of-type(3n) {
            background-color: $color_yellow;

            .project-card {
                border-color: $color_yellow_light;
            }
        }

        &:nth-of-type(4n) {
            background-color: $color_orange;

            .project-card {
                border-color: $color_orange_light;
            }
        }

        @include respond-to('tablet') {
            margin-top: 30px;
            .project-card {
                width: calc( (100% - 20px )/2);
            }
        }

        @include respond-to('tablet-h') {
            padding: 40px;
            gap: 30px; 
            .project-card {
                width: calc( (100% - (30px * 2) )/3);
            }
        }
    }
}