.partners-section {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.partners {
    &__list {
        margin-top: 20px;
        gap: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        align-self: stretch;
        flex-wrap: wrap;

        @include respond-to('tablet-h') {
            margin-top: 30px;
            gap: 75px;
        }

        a {
            display: block;
        }
        
    }

    &__logo {
        height: auto;
        width: 100px;

        @include respond-to('phone') {
            width: 130px;
        }

        @include respond-to('tablet-h') {
            height: 75px;
            width: auto;
        }
    }
}