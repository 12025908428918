
.camp-savings {
    
    &__tabs {
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 20px;

        @include flex(row, center, center);
        li {
            padding: 8px 18px;
            border-radius: 100px;
            border: 3px solid $color_blue;
            min-width: fit-content;
            cursor: pointer;
            transition: all .2s ease-in-out;

            @include flex(row, center, center);

            &:hover, &.active {
                background-color: $color_blue;
                font-weight: 700;
            }

            &:nth-of-type(2n) {
                border-color: $color_green;
                &:hover, &.active {
                    background-color: $color_green;
                }
            }
            
            &:nth-of-type(3n) {
                border-color: $color_yellow;
                &:hover, &.active {
                    background-color: $color_yellow;
                }
            }

            &:nth-of-type(4n) {
                border-color: $color_orange;
                &:hover, &.active {
                    background-color: $color_orange;
                }
            }

            &:nth-of-type(5n) {
                border-color: $color_pink;
                &:hover, &.active {
                    background-color: $color_pink;
                }
            }
        }
    }

    &__tab-container {
        margin-top: 20px;
    }

    &__tab {
        top: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        height: 0;
        max-width: 100%;
        position: absolute;
        overflow: hidden;
        padding: 40px 20px;
        border-radius: 15px;
        background-color: $color_blue;

        @include respond-to('tablet-h') {
            padding: 40px;
        }

        &[data-show="true"] {
            position: relative;
            z-index: 2;
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        &:nth-of-type(2n) {
            background-color: $color_green;

            .saving-card {
                border: 5px solid $color_green_light;
            }
        }
        
        &:nth-of-type(3n) {
            background-color: $color_yellow;

            .saving-card {
                border: 5px solid $color_yellow_light;
            }
        }

        &:nth-of-type(4n) {
            background-color: $color_orange;

            .saving-card {
                border: 5px solid $color_orange_light;
            }
        }

        &:nth-of-type(5n) {
            background-color: $color_pink;

            .saving-card {
                border: 5px solid $color_pink_light;
            }
        }
    }

    &__cards {
        margin-bottom: 30px;
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            flex-wrap: wrap;
            gap: 40px;
            @include flex(row, flex-start, stretch);

            .saving-card {
                width: calc(( 100% - (40px * 2)) / 3);
            }
        }
    }
}