.centered-content-section {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 70px 0;
    }
}

.centered-content {
    gap: 10px;
    text-align: center;

    @include flex(column, flex-start, center);

    @include respond-to('tablet') {
        gap: 20px;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        font-size: 2rem;
        line-height: 1.2;

        @include respond-to('tablet-h') {
            font-size: 3rem;
        }
    }

    &__subtitle {
        font-size: 1.5rem;
        line-height: 1.4;

        @include respond-to('tablet-h') {
            font-size: 2.5rem;
            line-height: 1.1;
        }
    }

    &__content {
        @include respond-to('tablet-h') {
            line-height: 1.5;
        }
    }
}