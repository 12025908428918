.hero-media-search {
    padding: 30px 0;
    background-color: $color_beige;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
        margin-top: -35px;
    }

    &__container {
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            gap: 40px;

            @include flex(row, space-between, center);

        }
    }

    &__content {
        @include respond-to('tablet-h') {
            width: 420px;

            @include flex(column, flex-start, stretch);
        }
    }

    &__media {
        @include respond-to('tablet-h') {
            width: calc( 100% - (420px + 40px));
        }
    }

    .heading-1 {
        text-align: center;
        letter-spacing: -0.56px;
        
        span {
            display: block;
            line-height: 1.2;
            letter-spacing: -1.68px;
        }

        @include respond-to('tablet-h') {
            text-align: left;
        }
    }

    &__frame {
        position: relative;

        .doodle--top-left {
            width: 84.277px;
            height: 78.987px;
            left: -30px;
            top: -8px;

            @include respond-to('tablet-h') {
                width: 183.21px;
                height: 171.71px;
                top: -54px;
                left: -47px;
            }
        }

        .doodle--bottom-right {
            width: 99.088px;
            height: 100px;
            right: -16px;
            bottom: -34px;

            @include respond-to('tablet-h') {
                width: 194.54px;
                height: 196.33px;
                right: -44px;
                bottom: -46px;
            }
        }
    }
    &__propositions {

        &.on-desktop {
            .wrapper__propositions{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100px;

                &:last-child {
                    margin-right: 30px;
                }
            }

            img {
                width: 58px;
            }

            h3 {
                font-family: Switzer;
                font-weight: 800;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: 0%;
                text-align: center;
                text-transform: capitalize;
            }

            p {
                font-family: Switzer;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: 0%;
                text-align: center;
                text-transform: capitalize;
            }

            @include respond-to('tablet-h') {
                align-items: flex-start;
                display: flex !important;
                justify-content: center;
                gap: 0 25px;
                margin: 15px 0 30px;
            }
        }

        &.on-mobile {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 0 25px;
            margin: 30px 0 15px;

            .wrapper__propositions{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 84px;
            }

            img {
                width: 48px;
            }

            h3 {
                font-family: Switzer;
                font-weight: 800;
                font-size: 14px;
                line-height: 120%;
                letter-spacing: 0%;
                text-align: center;
                text-transform: capitalize;
            }

            p {
                font-family: Switzer;
                font-weight: 500;
                font-size: 14px;
                line-height: 120%;
                letter-spacing: 0%;
                text-align: center;
                text-transform: capitalize;
            }
        }
    }
    &__form {
        text-align: center;

        @include respond-to('tablet-h') {
            text-align: left;
        }

        &.on-mobile {
            margin-top: 0px;
        }

        &.on-desktop{
            margin-top: 0px;
        }
        
        .heading-4 {
            margin-bottom: 18px;
        }

        .form__field {
            @include respond-to('phone') {
                input {
                    flex-grow: 1;
                }
            }
        }
    }

    &__video,
    &__image,
    &__embed {
        width: 100%;
        border-radius: 15px;
        border: 5px solid $color_white;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;

        @include respond-to('tablet-h') {
            border: 10px solid $color_white;
            max-width: 840px;
        }
    }

    &__embed {
        iframe {
            width: 100%;
            
            @include respond-to('phone') {
                height: 187px;
            }

            @include respond-to('tablet') {
                height: 400px;
            }

            @include respond-to('tablet-h') {
                height: 270px;
            }

            @include respond-to('laptop') {
                height: 382px;
            }

            @include respond-to('desktop') {
                height: 461px;
            }
        }
    }
}