.social-proof-slider-section {
    padding: 4rem 0;

    .social-proof-slider__header {
        text-align: center;
        margin-bottom: 4rem;

        h2 {
            font-family: "Switzer", sans-serif;
            font-weight: 700;
            font-size: 2rem;
        }
    }

    .social-proof-slider-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 2rem;
        text-align: center;

        &__photo {
            margin-bottom: 1rem;

            img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &__stars {
            margin-bottom: 1rem;
        }

        &__headline {
            font-weight: 700;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }

        &__sub-headline {
            font-weight: 700;
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }

        &__content {
            max-width: 300px;
        }

        &__message {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            font-weight: 400;
        }
    }
    


    .social-proof-slider-swiper {
        padding-bottom: 1rem;
        .swiper-wrapper {
            &:not(:has(.swiper-slide-visible)) {
                justify-content: center;
            }
        }

        &__controls {
            margin-top: 20px;
    
            @include flex(row, space-between, center);
    
            @include respond-to('tablet-h') {
                margin-top: 30px;
            }
        }
    
        &__button-prev {
            left: 0;
            transform: rotate(180deg);
        }

        &__button-next {
            right: 0;
        }

        &__button-next,
        &__button-prev {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            z-index: 10;
            background-color: $color_white;
            border-radius: 50%;
        }

        .btn-slider {
            width: 40px;
            height: 40px;
        }

        &__pagination {
            text-align: center;
    
            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }

        @include respond-to('tablet-h') {
            padding: 0 3rem;

            &__button-next,
            &__button-prev {
                bottom: unset;
                top: 45%;
            }

            .btn-slider {
                width: 48px;
                height: 48px;
            }
        }
    }

}

