.camp-faqs {
    @include respond-to('tablet-h') {
        gap: 80px;
        @include flex(row, space-between, flex-start);
    }

    &__left {
        &> .on-desktop {
            margin-top: 32px;
        }
    }

    &__right {
        @include respond-to('tablet-h') {
            max-width: 500px;
        }

        @include respond-to('laptop') {
            max-width: 720px;
        }
    }

    &__items {
        gap: 10px;
        margin-top: 30px;
        margin-bottom: 30px;

        @include flex(column, flex-start, stretch);
    }

    &__item {
        background-color: $color_charcoal;
        color: $color_white;
        padding: 20px;
        border-radius: 15px;
        border: 5px solid $color_white;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.4;

        &-title {
            gap: 10px;

            @include flex(row, space-between, center);

            @include respond-to('tablet-h') {
                font-size: 1.125rem;
            }

            svg {
                min-width: 24px;
                path {
                    fill: $color_white;
                }
            }
        }

        &-content {
            font-weight: 500;
            margin-top: 20px;
            a {
                color: $color_white;
            }
        }
    }
}