.values-section {
    padding: 50px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}


.value-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
        margin-bottom: 20px;
        width: 75px;
        height: 75px;
    }

    &__title {
        margin-bottom: 10px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
    }

    &__content {
        text-align: center;
        line-height: 1.4;
    }
}

.values {
    &__title,
    &__content {
        @include respond-to('tablet-h') {
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__title {
        font-size: 2rem;
        line-height: 1.2;
        letter-spacing: -0.32px;

        @include respond-to('tablet-h') {
            font-size: 36px;
            line-height: 1.1;
            letter-spacing: -0.36px;
        }
    }

    &__note {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
    }

    &-swiper {
        margin-top: 30px;
        margin-bottom: 30px;
        overflow: visible !important;

        @include respond-to('tablet-h') {
            margin-bottom: 50px;
        }

        .swiper-slide {
            max-width: 358px;
        }

        &__pagination {
            text-align: center;
            margin-top: 30px;

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }

    &-grid {
        gap: 30px;
        margin-top: 30px;

        @include flex(column, flex-start, center);

        @include respond-to('tablet') {
            flex-wrap: wrap;
            @include flex(row, flex-start, flex-start);

            .value-card {
                width: calc((100% - 30px)/2);
            }
        }

        &--4 {
            @include respond-to('tablet-h') {
                .value-card {
                    width: calc((100% - (30px * 3))/4);
                }
            }
        }

        &--3 {
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            .value-card {
                &__icon {
                    margin-bottom: 0;
                }
            }

            @include respond-to('tablet') {
                @include flex(row, center, flex-start);
            }

            @include respond-to('tablet-h') {
                .value-card {
                    width: calc((100% - (30px * 2))/3);
                }
            }
        }
    }
}

