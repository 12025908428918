.camp-caring-section {

}

.camp-caring {
    &__grid {
        margin-top: 30px;
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            gap: 32px;

            @include flex(row, space-between, stretch);
        }
    }

    &__item {
        display: flex;
        padding: 30px 20px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-radius: 10px;
        border: 5px solid $color_white;
        background: $color_white;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);

        @include respond-to('tablet-h') {
            width: calc(( 100% - (32px * 2)) / 3);
        }
    }

    &__name {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4
    }

    &__bottom {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__price {
        font-size: 36px;
        font-weight: 800;
        line-height: 1.1;
        letter-spacing: -0.36px;
        margin-top: 20px;
    }
}