.fourofour-template {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content: center;

    h1 {
        font-size: 15rem;
        font-weight: 700;
        letter-spacing: 1rem;
    }

    p {
        font-size: 2rem;
        font-weight: 400;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}