// Colors
$color_beige: #F6F1E9;
$color_beige_light: #FCFAF5;
$color_blue: #39B7EA;
$color_blue_light: #CCE8EF;
$color_yellow: #FFDA00;
$color_yellow_light: #FFF2C6;
$color_green: #77C043;
$color_green_light: #DBE9C5;
$color_orange: #F7901E;
$color_orange_light: #FBDCB9;
$color_pink: #F593A5;
$color_pink_light: #FDE5E3;
$color_black: #0C161F;
$color_charcoal: #16212D;
$color_white: #FFFFFF;
$color_gray: #E9E9E9;
$color_black_opacity: rgba($color_black, 0.10);

// Typography
$font_general: 'Switzer', sans-serif;
$font_script: 'Kalam', serif;