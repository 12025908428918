
.container {
    padding: 0 1rem;

    @include respond-to('tablet') {
        margin-left: auto;
        margin-right: auto;
        max-width: 720px;
        padding: 0;
    }

    @include respond-to('tablet-h') {
        max-width: 960px;
    }

    @include respond-to('laptop') {
        max-width: 1160px;
    }
    
    @include respond-to('desktop') {
        max-width: 1300px;
    }
}

.posts-grid {
    gap: 20px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet') {
        
        flex-wrap: wrap;
        @include flex(row, flex-start, stretch);

        .blog-card {
            width: calc((100% - 20px)/2)
        }
    }

    @include respond-to('laptop') {
        .blog-card {
            width: calc((100% - (20px * 2))/3)
        }
    }

    @include respond-to('desktop') {
        gap: 30px;
        .blog-card {
            width: calc((100% - (30px * 2))/3)
        }
    }

    &__title {
        font-size: 2rem;
        margin-bottom: 40px;
    }
}
