.tabs-image-accordion-section {
    padding: 30px 0 50px;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.tab-image-accordion {
    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row, space-between, flex-start)
    }

    &__media {
        @include respond-to('tablet-h') {
            min-width: 400px;
        }
        @include respond-to('laptop') {
            min-width: 610px;
        }
    }

    &__body {
        margin-top: 40px;
        @include respond-to('tablet-h') {
            margin-top: 0;
        }
    }

    &__title {
        margin-bottom: 10px;
        font-size: 2rem;
    }

    &__items {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 20px;
        position: relative;

        @include flex(column, flex-start, stretch);

        &::after {
            content: "";
            width: 5px;
            background-color: rgba($color_black, .1);
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 5px;
            position: absolute;
        }

        .scrollbar {
            content: "";
            width: 5px;
            background-color: $color_blue;
            left: 0;
            height: 130px;
            border-radius: 5px;
            position: absolute;
            transition: all .2s;

            &.blue {
                background-color: $color_blue;
            }

            &.green {
                background-color: $color_green;
            }

            &.yellow {
                background-color: $color_yellow;
            }
            &.pink {
                background-color: $color_pink;
            }
            &.orange {
                background-color: $color_orange;
            }

            @include respond-to('desktop') {
                height: 93px
            }
        }
    }

    &__item {
        position: relative;
        
        
        input[type="radio"] {
            display: none;
        }

        &-title {
            gap: 10px;
            cursor: pointer;

            @include flex(row, flex-start, center);

            svg {
                width: 35px;
                height: 35px;
            }
        }

        &-content {
            height: 0;
            overflow: hidden;
            margin-left: 45px;
            transition: all .2s ease;
            margin-bottom: 30px;

            p {
                padding-top: 10px;
            }
        }

        &:last-of-type {
            .tab-image-accordion__item-content {
                margin-bottom: 0;
            }
        }

    }

    &__toggle {
        &:checked + .tab-image-accordion__item-content {
            height: auto;

            @include respond-to('desktop') {
                height: auto;
            }
        }
    }
}

.tabs-image-accordion {
    &__header {
        @include respond-to('laptop') {
            @include flex(row, space-between, flex-end);
        }
    }

    &__right {
        @include respond-to('laptop') {
            max-width: 631px;
        }
    }

    &__buttons {
        margin-top: 20px;
        overflow: visible !important;
    }

    &__button {
        padding: 8px 18px;
        border-radius: 100px;
        font-size: 18px;
        line-height: 1.5;
        width:fit-content;
        border: 3px solid $color_blue;
        cursor: pointer;
        
        @include flex(row, center, center);

        &:hover, &.swiper-slide-thumb-active {
            background-color: $color_blue;
            font-weight: 700;
        }

        &.green {
            border-color: $color_green;

            &:hover, &.swiper-slide-thumb-active {
                background-color: $color_green;
            }
        }

        &.yellow {
            border-color: $color_yellow;
            
            &:hover, &.swiper-slide-thumb-active {
                background-color: $color_yellow;
            }
        }

        &.pink {
            border-color: $color_pink;
            
            &:hover, &.swiper-slide-thumb-active {
                background-color: $color_pink;
            }
        }
        &.orange {
            border-color: $color_orange;
            
            &:hover, &.swiper-slide-thumb-active {
                background-color: $color_orange;
            }
        }
    }


    &__tabs {
        margin-top: 22px;
        overflow: visible !important;

        &> .swiper-wrapper > .swiper-slide {
            opacity: 0 !important;

            &.swiper-slide-visible {
                opacity: 1 !important;
            }
        }

        @include respond-to('laptop') {
            margin-top: 50px;
        }
    }

    &__items {
        overflow: visible !important;
        margin-bottom: 30px;

        .swiper-slide:not(:last-of-type) {
            width: 300px !important;
        }

        &-scrollbar {
            @extend %swiper-scrollbar;

            margin-top: 20px;

            &.blue {
                .swiper-scrollbar-drag {
                    background-color: $color_blue;
                }
            }

            &.green {
                .swiper-scrollbar-drag {
                    background-color: $color_green;
                }
            }

            &.yellow {
                .swiper-scrollbar-drag {
                    background-color: $color_yellow;
                }
            }
            &.pink {
                .swiper-scrollbar-drag {
                    background-color: $color_pink;
                }
            }
            &.orange {
                .swiper-scrollbar-drag {
                    background-color: $color_orange;
                }
            }
        }
    }

    &__item {
        &> img {
            margin-bottom: 10px;
        }

        &> .heading-3 {
            line-height: 1.4;
        }
    }
}