.search-bar {
    background-color: $color_beige;
    border-radius: 15px;
    padding: 40px 20px;
    margin-right: 16px;
    margin-left: 16px;
    position: relative;

    @include respond-to('tablet-h') {
        min-width: 600px;
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($color_black, 0.4);
        z-index: 9999;
        display: none;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease-in-out;

        &.opened {
            visibility: visible;
            opacity: 1;
            display: flex;
        }
    }

    &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 5px;
    }
}