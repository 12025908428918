.testimonials-section__home {
    @include respond-to('tablet-h') {
        padding: 40px 0 75px !important;
    }
    .testimonials {
        position: relative;

        @include respond-to('tablet-h'){
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }

        @include respond-to('desktop'){
            max-width: 1090px;
        }

        &__header {
            gap: 30px;
            margin-bottom: 46px;

            @include flex(column, center, center);

            img {
                position: absolute;
                display: block;
                width: 54.84px;
                height: 50px;
                top: 44px;
                left: calc(50% - 130px);
                object-fit: contain;
                z-index: 10;

                @include respond-to('tablet-h') {
                    align-items: flex-start;
                    position: unset;
                }

                @include respond-to('desktop') {
                    width: 82.25px;
                    height: 75px;
                }
            }

            @include respond-to('tablet-h') {
                align-items: flex-start;
                margin-bottom: -50px;

                &--left {
                    justify-content: flex-end;
                }
            }

            @include respond-to('desktop') {
                margin-bottom: -100px;
            }
        }

        &__title {
            font-size: 2.25rem;
            line-height: 1.2;
            color: $color_blue;

            @include respond-to('tablet-h') {
                font-size: 3.5rem;
                line-height: 1;
                text-align: center;
            }

            @include respond-to('desktop') {
                max-width: 700px;
            }
        }
        .container__button {
            @include flex(row, center, center);
            margin-top: 20px;

            @include respond-to('tablet-h') {
                margin-top: 0px;
            }

            a.btn {
                z-index: 100;

                @include respond-to('tablet-h') {
                    margin-top: 0px;
                    position: absolute;
                    bottom: 55px;
                    left: 110px;
                }

                @include respond-to('desktop') {
                    bottom: 80px;
                    left: 175px;
                }
            }
        }
    }

    .testimonials-swiper {
        overflow: visible !important;

        .swiper-slide {
            &:not(:nth-child(1)) {
                transition: all .2s ease-in-out !important;
                opacity: 0 !important;

                &.swiper-slide-visible {
                    opacity: 1 !important;
                }
            }
        }

        &__button-prev,
        &__button-next {
            display: flex;
            position: absolute;
            background: white;
            border-radius: 50%;
            top: 81%;
            cursor: pointer;
            z-index: 100;

            span {
                border: 0;
                width: 40px;
                height: 40px;
            }

            @include respond-to('tablet-h') {
                top: 30%;
                bottom: unset;
            }

            @include respond-to('desktop') {
                top: 37%;
            }
        }

        &__button-prev {
            left: calc(50% - 185px);
            transform: rotate(180deg);
            box-shadow: 2px -3px 0 0 #0C161F;

            @include respond-to('tablet-h') {
                left: -17px;
            }
        }

        &__button-next {
            left: calc(50% + 145px);
            box-shadow: 2px 3px 0 0 #0C161F;

            @include respond-to('tablet-h') {
                left: 390px;
            }

            @include respond-to('desktop') {
                left: 525px;
            }
        }

        &__pagination {
            margin-top: 15px;
            text-align: center;

            @include respond-to('tablet-h') {
                margin-top: 0;
                z-index: 100;
                height: 50px;
                position: absolute;
                left: 135px !important;
                width: 148px !important;
                bottom: 105px !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @include respond-to('desktop') {
                bottom: 135px !important;
                left: 200px !important;
            }

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }

        @include respond-to('tablet-h') {
            &--left {
                .testimonials-swiper__button-prev {
                    left: unset;
                    right: 196px;
                }

                .testimonials-swiper__button-next {
                    left: unset;
                    right: 0px;
                }

                .testimonials-swiper__pagination {
                    left: unset !important;
                    right: 48px !important;
                }
            }
        }
    }

    .testimonial {
        @include flex(column, flex-start, center);

        @include respond-to('tablet-h') {
            @include flex(row, space-between, flex-start);
        }
        &__media {
            .icon_two {
                display: none;
                position: absolute;
                width: 140px;
                height: 130px;
                right: -75px;
                top: -15px;
                z-index: 10;

                @include respond-to('tablet-h') {
                    display: block;
                    right: -75px;
                    top: -35px;
                    z-index: 10;
                }
                @include respond-to('desktop') {
                    top: -15px;
                    z-index: 10;
                }
            }
        }

        &__photo {
            background-color: $color_white;
            padding: 16px 16px 0;
            width: 300px;
            height: 400px;
            filter: drop-shadow(5.793px 5.793px 8.689px rgba(0, 0, 0, 0.15));
            transform: rotate(8deg);


            img {
                width: 100%;
                height: 330px;
                object-fit: cover;
                object-position: center;

                @include respond-to('desktop'){
                    height: 450px;
                }
            }

            figcaption {
                font-family: $font_script;
                font-size: 18px;
                line-height: 1.4;
                height: 50px;

                @include flex(row, center, center);

                @include respond-to('desktop') {
                    font-size: 24px;
                    line-height: 1.2;
                    height: 84px;
                }
            }

            @include respond-to('desktop'){
                padding: 24px 24px 0;
                width: 460px;
                height: 550px
            }
        }

        &__message {
            padding: 23px 28px;
            max-width: 340px;
            min-width: 340px;
            background-color: $color_charcoal;
            color: $color_white;
            line-height: 1.4;
            border-radius: 20px;
            border: 4px solid $color_beige;
            filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.10));
            position: relative;
            text-align: center;

            @include respond-to('tablet-h') {
                max-width: 449px;
                max-height: 364px;
                padding: 43px 40px;
                margin-top: 55px;
            }

            @include respond-to('desktop') {
                max-width: 550px;
                font-size: 1.5rem;
                margin-top: 125px;

                p {
                    font-size: inherit;
                }
            }

            &::after {
                content: '';
                width: 27px;
                height: 28px;
                position: absolute;
                top: -28px;
                right: 80px;
                background-image: url("/img/caret.png");
                display: none;
            }
        }

        &__subtext {
            font-size: 12px;
            font-weight: 700;
            margin-top: 10px;
            margin-right: 10px;
            text-align: right;

            @include respond-to('tablet-h') {
                font-size: 14px;
            }

            @include respond-to('desktop') {
                font-size: 1rem;
            }
        }

        &--right {
            gap: 25px 0;
        }

        @include respond-to('tablet-h') {
            &--right {
                flex-direction: row-reverse;
                gap: 0 32px;

                .testimonial__subtext {
                    text-align: left;
                    margin-right: 0;
                    margin-left: 10px;
                }
            }

            &--left {
                .testimonial__photo {
                    transform: rotate(-8deg);
                }
            }
        }
    }
}