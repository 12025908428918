.form,
form {
    input,
    select,
    textarea {
        border: 2px solid $color_black;
        border-radius: 100px;
        background-color: $color_white;
        padding: 12px 25px;
        font-size: 15px;
        font-weight: 400;
        color: $color_black;

        &::placeholder {
            color: rgba($color_black, .3);
        }
    }

    br {
        display: none;
    }

    .full-width {
        width: 100%;
    }

    &__field {
        gap: 10px;
        flex-wrap: wrap;

        @include flex(row, center, flex-start);
    }

    &__checkbox {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    &__input {
        margin-bottom: 25px;
        label {
            display: flex;
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
        }
    }

    .message {
        text-align: center;
        display: block;

        &.hidden {
            display: none;
        }
    }
}

.contact-form {
    max-width: 556px;
    margin-left: auto;
    margin-right: auto;
}

.wpforms-field {

    input:not([type="submit"]):not([type="checkbox"]), textarea {
        border-radius: 50px !important;
        padding: 0 0 0 20px !important;
        border: 2px solid $color_black !important;
    }

    textarea {
        padding: 20px 0 0 20px !important;
    }
}

.wpforms-submit-container {
    button[type="submit"] {
        background-color: #FFDA00 !important;
        border: 2px solid #0C161F !important;
        border-radius: 100px !important;
        font-family: inherit !important;
        font-size: 1rem !important;
        font-weight: 700 !important;
        color: $color_black !important;
        min-height: 46px;
        padding: 12px 16px !important;
        text-decoration: none !important;
        box-shadow: 2px 3px 0 0 #0C161F !important;
        transition: box-shadow 0.2s ease-in-out !important;
        cursor: pointer !important;
        box-shadow: 2px 3px 0 0 #0C161F !important;
        transition: box-shadow 0.2s ease-in-out;    
    }

    button[type="submit"]:hover {
        box-shadow: 0 0 0 0 #0C161F !important;
        background: #FFDA00 !important;
        outline: none !important;
    }
}