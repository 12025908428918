.hero-image-section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
        margin-top: -35px;
    }
}

.hero-image {
    gap: 30px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row, space-between, center);

        &--left {
            flex-direction: row-reverse;
        }
    }

    @include respond-to('desktop') {
        gap: 80px;
    }

    &__suptitle {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    &__frame {
        position: relative;

        .doodle--top-right {
            width: 121.408px;
            height: 98.036px;
            top: -29px;
            right: -32px;

            @include respond-to('tablet-h') {
                display: none;
            }
        }

        .doodle--top-left {
            display: none;

            @include respond-to('tablet-h') {
                display: block;
                // width: 171.408px;
                height: 120px;
                top: -40px;
                left: -40px;
            }
        }

        .doodle--bottom-right {
            bottom: -20px;
            right: -30px;
            width: 121.408px;
            height: 98.036px;

            @include respond-to('tablet-h'){
                width: 130.408px;
                height: auto;
                bottom: -30px;
                right: -30px; 
            }
        }

        .doodle--bottom-left {
            width: 121.408px;
            height: 98.036px;
            bottom: -20px;
            left: -40px;

            @include respond-to('tablet-h'){
                width: 171.408px;
                height: 192.036px;
                bottom: -68px;
                left: -54px; 
            }
        }
    }

    &__media, &__body {
        @include respond-to('tablet-h') {
            min-width: 460px;
        }

        @include respond-to('laptop') {
            min-width: 540px;
        }

        @include respond-to('desktop') {
            min-width: 610px;
        }
    }
}