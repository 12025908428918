.camp-info-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0;
    }
}

.camp-info {

    &__buttons {
        margin-top: 30px;
        gap: 10px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet') {
            flex-wrap: wrap;
            @include flex(row, flex-start, stretch);
        }

        @include respond-to('laptop') {
            gap: 32px 30px;
        }

        li {
            @include respond-to('tablet') {
                width: calc((100% - 10px ) / 2);
            }

            @include respond-to('laptop') {
                width: calc((100% - (32px * 2) ) / 3);
            }
        }
    }
}