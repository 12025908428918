.accordion-slider-section {
    padding: 10px 0 30px;
    // overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.accordion-slider-tab {

    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row, space-between, center);
    }

    @include respond-to('laptop') {
        gap: 80px;
    }

    &__media {
        margin-bottom: 40px;

        @include respond-to('tablet-h') {
            min-width: 500px;
            margin-bottom: 0px;
        }

        @include respond-to('laptop') {
            min-width: 650px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        font-size: 2rem;

        @include respond-to('laptop') {
            font-size: 3rem;
        }
    }

    &__content {
        p, h1, h2, h3, h4, h5 {
            margin-bottom: 16px;
        }

        ul, ol {
            line-height: 1.4;
            list-style: initial;
            padding-left: 20px;
        }
    }

    &__list {
        margin-top: 10px;
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            margin-top: 30px;
        }

        &-title {
            font-size: 20px;
            line-height: 1.4;
        }

        &-icon {
            width: 35px;
            height: 35px;
            object-fit: contain;
            display: block;
        }
    }
}

.accordion-slider {
    &__buttons {
        overflow: hidden;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: $color_beige;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-bottom: 30px;

        @include respond-to('tablet-h') {
            margin-bottom: 50px;
        }
    }
    &__button {
        padding: 8px 18px;
        border-radius: 100px;
        font-size: 1.125rem;
        line-height: 1.5;
        border: 3px solid $color_green;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 280px;
        
        &:hover,
        &.swiper-slide-thumb-active {
            background-color: $color_green;
            font-weight: 700;
        }

        &--pink {
            border-color: $color_pink;
            &:hover,
            &.swiper-slide-thumb-active {
                background-color: $color_pink;
            }
        }

        &--yellow {
            border-color: $color_yellow;
            &:hover,
            &.swiper-slide-thumb-active {
                background-color: $color_yellow;
            }
        }

        &--blue {
            border-color: $color_blue;
            &:hover,
            &.swiper-slide-thumb-active {
                background-color: $color_blue;
            }
        }

        &--orange {
            border-color: $color_orange;
            &:hover,
            &.swiper-slide-thumb-active {
                background-color: $color_orange;
            }
        }
        
    }

    &-buttons {
        overflow: visible !important;

        @include respond-to('tablet-h') {
            .swiper-wrapper {
                justify-content: center;
            }
        }
    }

    &-tabs {
        .swiper-slide {
            opacity: 0 !important;
            transition: opacity .2s ease-in-out;

            &.swiper-slide-active {
                opacity: 1 !important;
            }
        }

        &__controls {
            display: none;

            @include respond-to('tablet-h') {
                margin-top: 45px;

                @include flex(row, space-between, center);
            }
        }

        &__button-prev,
        &__button-next {
            z-index: 100;

            @include respond-to('tablet-h') {
                display: flex;
            }
        }

        &__button-prev {
            transform: rotate(180deg);
        }

        &__pagination {
            text-align: center;

            .swiper-pagination-bullet-active {
                background-color: $color_black;
            }
        }
    }

    &__items {
        margin-top: 30px;
        padding-left: 20px;
        position: relative;

        @include flex(column, flex-start, stretch);

        &::after {
            content: "";
            width: 5px;
            background-color: rgba($color_black, .1);
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 5px;
            position: absolute;
        }

        .scrollbar {
            content: "";
            width: 5px;
            background-color: $color_green;
            left: 0;
            height: 110px;
            border-radius: 5px;
            position: absolute;
            transition: all .2s;

            &--pink {
                background-color: $color_pink;
            }

            &--yellow {
                background-color: $color_yellow;
            }

            &--blue {
                background-color: $color_blue;
            }

            &--orange {
                background-color: $color_orange;
            }
        }
    }

    &__item {
        position: relative;
        
        
        input[type="radio"] {
            display: none;
        }

        &-title {
            gap: 10px;
            cursor: pointer;

            @include flex(row, flex-start, center);

            svg, img {
                width: 35px;
                height: 35px;
            }
        }

        &-content {
            height: 0;
            overflow: hidden;
            margin-left: 45px;
            transition: all .2s ease;
            margin-bottom: 30px;

            p {
                padding-top: 10px;
            }
        }

        &:last-of-type {
            .accordion-slider__item-content {
                margin-bottom: 0;
            }
        }

    }

    &__toggle {
        
        &:checked + .accordion-slider__item-content {
            height: unset;
        }
    }
}