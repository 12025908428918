.accordion-js {
    .accordion-js-toggle {
        cursor: pointer;
        svg {
            transition: all .2s ease-in-out;
        }

        &.is-open img:last-child,
        &.is-open svg:last-child{
            transform: rotate(180deg);
        }
    }
    .accordion-js-content {
        max-height: 0;
	    transition: all .2s ease-in-out;
        overflow: hidden;

        &.is-open {
            max-height: fit-content;
        }
    }
}