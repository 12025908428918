.activity {
    gap: 30px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 40px;

        @include flex(row-reverse, flex-start, flex-start);
    }

    @include respond-to('laptop') {
        gap: 70px;
    }

    &__body {
        @include respond-to('tablet-h') {
            min-width: 360px;
        }

        @include respond-to('laptop') {
            min-width: 400px;
        }
    }

    &__thumbs {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        list-style: none !important;
        padding-left: 0 !important;
        gap: 10px;
        flex-wrap: wrap;

        li {
            width: calc( (100% - (10px * 3)) / 4);
        }
    }

    &__thumb {
        object-fit: cover;
        display: block;
        width: 100%;
        border-radius: 15px;
        aspect-ratio: 1;
        border: 5px solid transparent;
        cursor: pointer;

        &.active,
        &:hover {
            border: 5px solid $color_white;
        }

    }

    &__media {
        @include respond-to('laptop') {
            min-width: 550px;
            max-width: 550px;
        }
    }

    &__image {
        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
        }

        figcaption {
            margin-top: 20px;
        }
    }

    &__status {
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 20px;
            border-radius: 100px;
            text-transform: capitalize;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.4;

            &.group-k-1st {
                background-color: $color_blue;
            }

            &.group-2nd-3rd {
                background-color: $color_green;
            }

            &.group-4th-5th {
                background-color: $color_yellow;
            }

            &.group-6th-8th {
                background-color: $color_orange;
            }

            &.group-8th-10th {
                background-color: $color_pink;
            }
        }
    }

    &__title {
        font-size: 28px;
        line-height: 1.2;

        @include respond-to('tablet-h') {
            font-size: 32px;
        }
    }

    &__week {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 10px;
    }

    &__price {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 700;
    }

    &__extras {
        margin-top: 30px;
        gap: 20px;

        @include flex(column, flex-start, stretch );
    }

    &__subcopy {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 400;
    }

    &__content {
        margin-top: 24px;
        * {
            margin-bottom: 16px;
        }
        ul, ol {
            list-style: initial;
            padding-left: 16px;
        }
    }

    &__buttons {
        margin-top: 24px;
    }

    &__extras {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &__extra {
        background-color: $color_white;
        border-radius: 10px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        line-height: 1.4;
        padding: 20px;

        h5 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        p, ul, ol {
            margin-top: 10px;
            line-height: 1.4;
        }
    }
}