.newsletter-sidebar {
    padding: 30px;
    background-color: $color_white;
    border-radius: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    min-width: 400px;

    &__title {
        font-size: 32px;
    }

    @include respond-to('tablet-h') {
        position: sticky;
        top: 235px;
    }
}