.image-content-large-section {
    padding: 50px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.image-content-large {
    gap: 50px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 80px;

        @include flex(row, space-between, center);
    }

    &__media {
        @include respond-to('tablet-h') {
            width: 494px;
        }

        @include respond-to('laptop') {
            width: 694px;
        }
    }

    &__body {
        @include respond-to('tablet-h') {
            width: calc( 100% - (494px + 80px));
        }

        @include respond-to('laptop') {
            width: calc( 100% - (694px + 80px));
        }
    }

    &__image {
        position: relative;

        .doodle--top-right {
            width: 140px;
            top: -24px;
            right: -16px;

            @include respond-to('laptop') {
                width: 281px;
                top: -46px;
                right: -36px;
            }
        }

        .doodle--bottom-left {
            width: 144px;
            bottom: -38px;
            left: -22px;

            @include respond-to('laptop') {
                width: 198px;
                bottom: -59px;
                left: -60px;
            }
        }
    }

    &__logo {
        width: 133px;
        height: 75px;
        object-fit: contain;
        margin-bottom: 20px;
    }
}