.camp-schedule-section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 50px 0 0;
    }
}

.camp-schedule {
    &__overflow {
        width: 100%;
        overflow-x: scroll;
    }
    &__table {
        width: 100%;
        overflow-x: scroll;
        border-radius: 10px;
        border-collapse:separate;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
        margin-top: 30px;
        background-color: $color_white;

        @include respond-to('tablet-h') {
            border: 10px solid $color_white;
            border-collapse: separate;
            border-spacing: 0 8px;
        }
        
        thead {
            th {
                background-color: $color_white;
                padding: 20px 10px;
                text-align: center;
                vertical-align: middle;
                font-size: 16   px;
                font-weight: 700;
                line-height: 1.4;
                
                &:first-child {
                    background-color: $color_gray;
                    border-radius: 10px 0 0 0;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                }
                
                &:last-child {
                    border-radius: 0 10px 0 0;
                }
                
                //&:not(:last-of-type) {
                    //border-right: 1px solid rgba(0, 0, 0, 0.20);
                    //}
                    

                @include respond-to('tablet-h') {
                    font-size: 20px;

                    &:first-child {
                        border-radius: 10px 0 0 10px;
                    }
                }


                @include respond-to('tablet-down') {
                    text-align: left;
                    padding: 20px;

                    strong {
                        font-size: 20px;
                    }
                }


                p {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        tbody {
            tr {

                @include respond-to('tablet-h') {
                    padding: 50px 0;

                    border-spacing: 10px;

                    td:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }

                    td {

                    }
                }



                &:nth-child(1) {
                    td:first-child {
                        background-color: $color_blue;
                    }

                    td {
                        background-color: $color_blue;

                        @include respond-to('tablet-h') {
                            .activity-card {
                                background-color: $color_blue_light;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    td:first-child {
                        background-color: $color_green;
                    }

                    td {
                        background-color: $color_green;

                        @include respond-to('tablet-h') {
                            .activity-card {
                                background-color: $color_green_light;
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    td:first-child {
                        background-color: $color_yellow;
                    }

                    td {
                        background-color: $color_yellow;

                        @include respond-to('tablet-h') {
                            .activity-card {
                                background-color: $color_yellow_light;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    td:first-child {
                        background-color: $color_orange;
                    }

                    td {
                        background-color: $color_orange;

                        @include respond-to('tablet-h') {
                            .activity-card {
                                background-color: $color_orange_light;
                            }
                        }
                    }
                }
                &:nth-child(5) {
                    td:first-child {
                        background-color: $color_pink;
                    }

                    td {
                        background-color: $color_pink;

                        @include respond-to('tablet-h') {
                            .activity-card {
                                background-color: $color_pink_light;
                            }
                        }
                    }
                }

                &:not(:nth-child(1)) {
                    padding: 0;
                }
            }
            
            tr {
                &:last-of-type {
                    td:first-child {
                        border-radius: 0 0 0 10px;

                        @include respond-to('tablet-h') {
                            border-radius: unset;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }
                    }

                    td:last-child {
                        padding: 10px;
                        border-radius: 0 0 10px 0;

                        @include respond-to('tablet-h') {
                            padding: 0.3rem 0.6rem 0.3rem 0.3rem;
                            border-radius: unset;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
            }
            td {
                background-color: $color_white;
                padding: 12px;
                position: relative;
                
                @include respond-to('tablet-h') {
                    padding: 20px;
                    height: 1px;
                    vertical-align: middle;
                    
                    &:not(:nth-child(1)) {
                        padding: 0.3rem 0.3rem;

                        &:last-child {
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                            padding: 0.3rem 0.6rem 0.3rem 0.3rem;
                        }
                    }
                }

                // &:not(:last-of-type) {
                //     //border-right: 1px solid rgba(0, 0, 0, 0.20);
                // }

                &:first-of-type {
                    text-align: left;
                    vertical-align: middle;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.4;
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    height: 1px;
                    width: 30%;

                    @include respond-to('tablet-h') {
                        min-width: 300px;
                        font-size: 20px;
                    }
                }

                h2 {
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        // &--pink {
        //     thead {
        //         th {
        //             &,
        //             &:nth-of-type(2n),
        //             &:nth-of-type(3n),
        //             &:nth-of-type(4n),
        //             &:nth-of-type(5n) {
        //                 background-color: $color_pink; 
        //             }
        //         }
        //     }

        //     tbody {
        //         td {
        //             &,
        //             &:nth-of-type(2n),
        //             &:nth-of-type(3n),
        //             &:nth-of-type(4n),
        //             &:nth-of-type(5n) {
        //                 background-color: $color_pink_light; 
        //             }

        //             &:first-of-type {
        //                 text-align: left;
        //             }
        //         }
        //     }

        //     .activity-card {
        //         @include respond-to('laptop') {
        //             min-width: unset;
        //         }
        //     }
        // }

        &--week-mobile {
            background-color: $color_white;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            @include flex(column, center, center);
            font-size: 1.1rem;
            font-weight: 700;
            //padding: 15px;
            text-align: center;
        }

        &--8-9-10,
        &--k-1,
        &--4-5,
        &--6-7-8,
        &--2-3 {
            tbody td {
                padding: 10px 0 0 10px;
            }

            tr:last-of-type {
                td:first-child {
                    padding: 10px 0 10px 10px;
                }
            }

            tbody td:nth-of-type(2n) {
                padding: 10px 10px 0 10px;
            }


            thead {
                th {
                    &:first-child {
                        height: 1px;
                        width: 30%;
                        padding: 10px 0 0 10px;

                        .camp-schedule__table--week-mobile {
                            background-color: $color_gray;
                        }
                    }
                }
            }
        }

        &--8-9-10 {
            tbody td {
                background-color: $color_pink !important;
            }

            thead {
                th {
                    &:first-child {
                        background-color: $color_pink;
                    }
                }
            }

            thead th:nth-of-type(2n) {
                background-color: $color_pink;
            }

            tbody td:nth-of-type(2n) {
                //background-color: $color_pink_light !important;

                .activity-card {
                    background-color: $color_pink_light !important;
                }
            }
        }

        &--k-1 {
            tbody td {
                background-color: $color_blue !important;
            }

            thead {
                th {
                    &:first-child {
                        background-color: $color_blue;
                    }
                }
            }

            thead th:nth-of-type(2n) {
                background-color: $color_blue;
            }

            tbody td:nth-of-type(2n) {
                background-color: $color_blue !important;

                .activity-card {
                    background-color: $color_blue_light !important;
                }
            }
        }

        &--2-3 {
            tbody td {
                background-color: $color_green !important;
            }

            thead {
                th {
                    &:first-child {
                        background-color: $color_green;
                    }
                }
            }
            thead th:nth-of-type(2n) {
                background-color: $color_green;
            }

            tbody td:nth-of-type(2n) {
                background-color: $color_green !important;

                .activity-card {
                    background-color: $color_green_light !important;
                }
            }
        }

        &--4-5 {
            tbody td {
                background-color: $color_yellow !important;
            }

            thead {
                th {
                    &:first-child {
                        background-color: $color_yellow;
                    }
                }
            }

            thead th:nth-of-type(2n) {
                background-color: $color_yellow;
            }

            tbody td:nth-of-type(2n) {
                background-color: $color_yellow !important;

                .activity-card {
                    background-color: $color_yellow_light !important;
                }
            }
        }

        &--6-7-8 {
            tbody td {
                background-color: $color_orange !important;
            }

            thead {
                th {
                    &:first-child {
                        background-color: $color_orange;
                    }
                }
            }
            thead th:nth-of-type(2n) {
                background-color: $color_orange;
            }

            tbody td:nth-of-type(2n) {
                background-color: $color_orange !important;

                .activity-card {
                    background-color: $color_orange_light !important;
                }
            }
        }
    }

    &__tabs {
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 20px;

        @include flex(row, center, center);
        li {
            padding: 8px 18px;
            border-radius: 100px;
            border: 3px solid $color_blue;
            min-width: fit-content;
            cursor: pointer;
            transition: all .2s ease-in-out;

            @include flex(row, center, center);

            &:hover, &.active {
                background-color: $color_blue;
                font-weight: 700;
            }

            &:nth-of-type(2n) {
                border-color: $color_green;
                &:hover, &.active {
                    background-color: $color_green;
                }
            }
            
            &:nth-of-type(3n) {
                border-color: $color_yellow;
                &:hover, &.active {
                    background-color: $color_yellow;
                }
            }

            &:nth-of-type(4n) {
                border-color: $color_orange;
                &:hover, &.active {
                    background-color: $color_orange;
                }
            }

            &:nth-of-type(5n) {
                border-color: $color_pink;
                &:hover, &.active {
                    background-color: $color_pink;
                }
            }
        }
    }

    &__tab {
        top: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        height: 0;
        width: 100%;
        position: absolute;
        overflow: hidden;

        &[data-show="true"] {
            position: relative;
            z-index: 2;
            opacity: 1;
            visibility: visible;
            height: auto;
        }

        // &.on-mobile {
        //     .camp-schedule__table--pink {
        //         tbody {
        //             td {
        //                 &:first-of-type {
        //                     text-align: center;
        //                     background-color: $color_white; 
        //                 }
        //             }
        //         }

        //         thead {
        //             th {
        //                 &:first-of-type {
        //                     background-color: $color_gray; 
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}