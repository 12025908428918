.image-content-slider-section {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.image-content-slider {
    gap: 20px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h') {
        gap: 40px;
        @include flex(row, space-between, center);

        &--right {
            flex-direction: row-reverse;
        }
    }

    @include respond-to('desktop') {
        gap: 80px;
    }

    &__media, &__body {
        @include respond-to('tablet-h') {
            width: 460px;
        }

        @include respond-to('laptop') {
            width: 540px;
        }

        @include respond-to('desktop') {
            width: 610px;
        }
    }

    &__body {
        @include respond-to('tablet-down') {
            @include flex(column-reverse, flex-start, stretch);

            .image-content-swiper {
                &__controls {
                    margin: 0.5rem 0 2rem 0;
                }
            }
        }
    }

    &__content {
        line-height: 1.4;
    }

}

.image-content-swiper {
    &__controls {
        margin-top: 20px;

        @include flex(row, space-between, center);

        @include respond-to('tablet-h') {
            margin-top: 30px;
        }
    }

    &-left__button-prev,
    &-right__button-prev {
        transform: rotate(180deg);
    }

    &-left__pagination,
    &-right__pagination {
        text-align: center;

        .swiper-pagination-bullet-active {
            background-color: $color_black;
        }
    }
}