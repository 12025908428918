.footer {
    background-color: $color_charcoal;
    color: $color_white;
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 50px 0;
    }

    &__container {
        @include respond-to('tablet-h') {
            @include flex(row, space-between, flex-start);
        }
    }

    &__logo {
        display: block;
        width: 253px;
        margin: -20px;

        @include respond-to('tablet-h') {
            width: 317px;
            margin: -27px;
        }
    }

    &__social {
        margin-top: 30px;
        gap: 50px;
        flex-wrap: wrap;

        @include flex(row, flex-start, center);

        img {
            width: 32px;
            height: 32px;
            display: block;
        }
    }

    &__right {
        margin-top: 40px;
        gap: 30px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            margin-top: 0;
            gap: 40px;

            @include flex(row, flex-end, flex-start);
        }
    }

    &__copy {
        display: block;
        margin-top: 50px;
        font-size: .875rem;
        font-weight: 400;

        @include respond-to('tablet-h') {
            margin-top: 30px;
        }
    }

    .footer-menu {
        min-width: 200px;

        &__title {
            font-size: 1.75rem;
            line-height: 1.2;

            @include respond-to('tablet-h') {
                font-size: 2.25rem;
            }
        }

        &__items {
            margin-top: 10px;

            > li > a {
                display: inline-block;
                padding: 8px 0;
                font-weight: 700;
                font-size: 1rem;
                list-style: 1.4;
                color: $color_white;
                text-decoration: none;
            }
        }
    }
}