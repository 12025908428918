.image-accordion-section {
    padding: 50px 0;
}
.image-accordion {
    @include respond-to('tablet-h') {
        gap: 60px;

        @include flex(row, space-between, center);
    }

    &--right {
        @include respond-to('tablet-h') {
            @include flex(row-reverse, space-between, center);
        } 
    }

    &__content {
        @include respond-to('tablet-h') {
            width: 510px;
        }
    }

    &__items {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 20px;
        position: relative;

        @include flex(column, flex-start, stretch);

        &::after {
            content: "";
            width: 5px;
            background-color: rgba($color_black, .1);
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 5px;
            position: absolute;
        }

        .scrollbar {
            content: "";
            width: 5px;
            background-color: $color_blue;
            left: 0;
            height: 130px;
            border-radius: 5px;
            position: absolute;
            transition: all .2s;

            @include respond-to('desktop') {
                height: 113px
            }
        }
    }

    &__item {
        position: relative;
        
        
        input[type="radio"] {
            display: none;
        }

        &-title {
            gap: 10px;

            @include flex(row, flex-start, center);

            svg {
                width: 35px;
                height: 35px;
            }
        }

        &-content {
            height: 0;
            overflow: hidden;
            margin-left: 45px;
            transition: all .2s ease;
            margin-bottom: 30px;

            p {
                padding-top: 10px;
            }
        }

        &:last-of-type {
            .image-accordion__item-content {
                margin-bottom: 0;
            }
        }

    }

    &__toggle {
        &:checked + .image-accordion__item-content {
            height: unset;

            @include respond-to('desktop') {
                height: unset;
            }
        }
    }

    &-images {
        margin-top: 30px;
        overflow: visible !important;

        .swiper-slide {
            opacity: 0 !important;

            &.swiper-slide-visible {
                opacity: 1 !important;
            }
        }
    }

    &-thumbs {
        margin-top: 20px;
        margin-bottom: 30px;
        overflow: visible !important;

        .swiper-slide:not(:last-of-type) {
            width: 300px !important;
        }

        &__scrollbar {
            @extend %swiper-scrollbar;
        }
    }

    &__image {
        position: relative;
        transition: opacity .4s ease;

        &[data-show] {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        &[data-show="true"] {
            opacity: 1;
            visibility: visible;
            position: relative;
        }
        &[data-show="false"] {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            max-width: 504px;
        }

        &> .main-image {
            width: 100%;
            border-radius: 15px;
            border: 5px solid $color_white;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            overflow: hidden;

            @include respond-to('tablet-h') {
                border: 10px solid $color_white;
                max-width: 840px;
            }
        }

        .doodle--top-right {
            width: 73px;
            height: 73px;
            top: -23px;
            right: -23px;

            @include respond-to('tablet-h') {
                width: 138px;
                height: 138px;
                top: -45px;
                right: -45px;
            }
        }

        .doodle--bottom-left {
            width: 73px;
            height: 73px;
            bottom: -15px;
            left: -15px;

            @include respond-to('tablet-h') {
                width: 138px;
                height: 138px;
                bottom: -35px;
                left: -35px;
            }
        }
    }

    &__text {
        width: 300px;

        img {
            display: block;
            width: 35px;
            height: 35px;
            margin-bottom: 10px;
        }

        .heading-3 {
            line-height: 1.4;
        }

        p {
            line-height: 1.4;
        }
    }
}