.tabs-scheduler-section {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;

        &.padding-50 {
            padding: 50px 0;
        }
    }
}

.tabs-scheduler {
    gap: 20px;
    @include flex(column, flex-start, stretch);

    &__header {
        text-align: center;

        @include respond-to('tablet-h') {
            text-align: left;

            @include flex(row, space-between, flex-end);
        }
    }

    &__heading {
        @include respond-to('desktop') {
            max-width: 635px;
        }
    }

    &__title {
        font-size: 28px;
        line-height: 1.2;
        letter-spacing: -0.84px;

        @include respond-to('tablet-h') {
            font-size: 48px;
            line-height: 110%;
            letter-spacing: -1.44px;
        }
    }

    &__content {
        margin-top: 5px;
        font-weight: 500;
    }

    &__tabs {
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        @include flex(row, center, center);

        @include respond-to('tablet-h') {
            @include flex(row, flex-end, center);
        }
    }

    &__body {
        width: 100%;
        position: relative;
    }

    &__tab {
        border: 3px solid $color_blue;
        padding: 8px 18px;
        border-radius: 100px;
        min-width: 99px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.4;
        cursor: pointer;

        @include respond-to('desktop') {
            font-size: 20px;
            min-width: 115px;
        }

        &:nth-of-type(2n) {
            border-color: $color_green;
            &:hover, &.active {
                background-color: $color_green;
            }
        }

        &:nth-of-type(3n) {
            border-color: $color_yellow;
            &:hover, &.active {
                background-color: $color_yellow;
            }
        }

        &:nth-of-type(4n) {
            border-color: $color_orange;
            &:hover, &.active {
                background-color: $color_orange;
            }
        }

        &:nth-of-type(5n) {
            border-color: $color_pink;
            &:hover, &.active {
                background-color: $color_pink;
            }
        }

        &:hover, &.active {
            background-color: $color_blue;
            font-weight: 700;
        }
    }

    .tab-scheduler {
        background-color: $color_blue;
        padding: 30px 20px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
        width: 100%;

        @include respond-to('tablet-h') {
            gap: 50px;
            padding: 50px;

            @include flex(row, space-between, flex-start);
        }

        &[data-show="true"] {
            position: relative;
            z-index: 2;
            opacity: 1;
            visibility: visible;
        }


        &:nth-of-type(2n) {
            background-color: $color_green;
        }

        &:nth-of-type(3n) {
            background-color: $color_yellow;
        }

        &:nth-of-type(4n) {
            background-color: $color_orange;
        }

        &:nth-of-type(5n) {
            background-color: $color_pink;
        }

        &__title {
            font-size: 2rem;
            font-weight: 800;
            line-height: 1.2;
            letter-spacing: -0.32px;

            @include respond-to('tablet-h') {
                font-size: 3rem;
                line-height: 1.1;
                letter-spacing: -0.48px;
            }
        }

        &__content {
            margin-top: 10px;
        }

        &__image {
            display: block;
            margin-top: 20px;

            @include respond-to('tablet-h') {
                margin-top: 30px;
            }
        }
        
        &__body {
            @include respond-to('tablet-h'){
                width: calc( 100% - (320px + 50px));
            }

            @include respond-to('laptop'){
                width: calc( 100% - (400px + 50px));
            }

            @include respond-to('desktop'){
                width: calc( 100% - (460px + 50px));
            }
        }
        &__schedules {
            @include respond-to('tablet-h'){
                width: 320px;
            }

            @include respond-to('laptop'){
                width: 400px;
            }

            @include respond-to('desktop'){
                width: 460px;
            }
        }

        &__list {
            margin-top: 20px;
            margin-bottom: 30px;
            gap: 10px;

            @include flex(column, flex-start, stretch);

            @include respond-to('tablet-h') {
                margin-top: 0;
                
                
                .image-rounded {
                    display: none;
                }
            }

            @include respond-to('laptop') {
                gap: 17px;
                font-size: 20px;
            }
        }
    }

    .schedule-item {
        &__header {
            gap: 20px;

            @include flex(row, flex-start, center);

            img {
                width: 35px;
                height: 35px;
            }

            span,
            strong {
                flex-grow: 1;
            }

            strong {
                @include respond-to('tablet-h'){
                    font-weight: 800;
                }
            }
        }

        &__content {
            margin-left: 55px;
            font-size: 14px;

            @include respond-to('tablet-h'){
                font-size: 1rem;
            }
        }
    }
}