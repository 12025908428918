.newsletter {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }

    &__container {
        background-color: $color_pink;
        padding: 40px 20px;
        border-radius: 20px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            padding: 50px;

            @include flex(row, space-between, center);
        }
    }

    &__heading {
        text-align: center;

        @include respond-to('tablet-h') {
            text-align: left;
        }
    }

    &__title {
        font-size: 1.75rem;
        font-weight: 1.2;

        @include respond-to('laptop') {
            font-size: 3rem;
            font-weight: 1.1;
        }
    }

    &__content {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
    }

    &__heading,
    &__form {

        @include respond-to('tablet-h') {
            width: 46%;
        }
        
    }

    &__form {
        .input__field {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 10px;
            flex-wrap: wrap;

            @include respond-to('tablet-h') {
                flex-direction: row;
            }
            input[type="email"] {
                flex-grow: 1;
            }
        }

        .message {
            text-align: center;
            display: block;

            &.hidden {
                display: none;
            }
        }
    }
}