.article {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0 50px;
        margin-top: -35px;
    }

    &__meta {
        margin-bottom: 16px;
        gap: 16px;
        font-weight: 600;
        line-height: 1.5;

        @include flex(row, center, center);
    }

    &__category {
        padding: 6px 12px;
        border-radius: 200px;
        border: 1px solid $color_black;
        background-color: $color_white;
        text-decoration: none;
        color: $color_black;
        font-size: 12px;
        line-height: 1.5;
    }

    &__reading {
        font-size: 14px;
        line-height: 1.5;
    }

    &__image {
        margin-top: 30px;
        margin-bottom: 50px;

        @include respond-to('tablet-h') {
            margin-top: 50px;
            margin-bottom: 75px;

            min-width: 100%;
            height: 600px;
            object-fit: cover;
        }
    }

    &__body {
        margin-left: auto;
        margin-right: auto;
        max-width: 768px;

        @include respond-to('tablet-h') {
            padding-top: 50px;
        }
    }

    &__content {
        h2, h3, h4, h5, h6 {
            padding: 20px 0;

            @include respond-to('tablet-h') {
                padding: 24px 0;
            }
        }

        h2 {
            font-size: 32px;

            @include respond-to('tablet-h') {
                font-size: 36px;
            }
        }

        h3 {
            font-size: 28px;

            @include respond-to('tablet-h') {
                font-size: 32px;
            }
        }

        h4 {
            font-size: 24px;

            @include respond-to('tablet-h') {
                font-size: 28px;
            }
        }

        p {
            margin-bottom: 16px;
        }

        strong {
            font-weight: 700;
        }

        blockquote {
            margin: 20px 0 32px;
            padding-left: 24px;
            border-left: 2px solid $color_black;
            font-style: italic;
            font-size: 1rem;

            @include respond-to('tablet-h') {
                font-size: 1.25rem;
            }
        }

        img {
            border: 5px solid $color_white;
            border-radius: 15px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-width: 100%;
            height: auto;
            margin-top: 10px;
            margin-bottom: 10px;

            @include respond-to('tablet-h') {
                border: 10px solid $color_white;
                margin-top: 48px;
                margin-bottom: 48px;
            }
        }

        ul, ol {
            line-height: 1.4;
            list-style: initial;
            padding-left: 20px;
            margin-bottom: 1rem;
        }
    }

    &__author {
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid $color_black;
        gap: 16px;
        @include flex(row, flex-start, center);

        @include respond-to('tablet-h') {
            padding-top: 48px;
            margin-top: 48px;
        }

        &-photo {
            border-radius: 56px;
            overflow: hidden;
            width: 56px;
            height: 56px;
        }

        &-name {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
        }
    }
}