.faqs {
    padding: 30px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }

    &__title {
        line-height: 1.1;
    }

    &__header, &__form {
        max-width: 544px;
        margin-left: auto;
        margin-right: auto;
    }

    &__form {
        margin-top: 30px;

        @include respond-to('tablet-h') {
            .form__field {
                input {
                    flex-grow: 1;
                }
            }
        }
    }

    &__body {
        

        @include respond-to('tablet-h') {
            @include flex(row, flex-start, stretch);
        }
    }

    &__content, &__menu {
        @include respond-to('tablet-h') {
            padding-top: 75px
        }
    }
    &__content {

        
        @include respond-to('tablet-h') {
            padding-left: 60px;
        }

        @include respond-to('laptop') {
            padding-left: 120px;
        }

        &--search {
            padding-left: 0 !important;
        }
    }

    &__menu {
        position: sticky;
        background-color: $color_beige;
        top: 60px;
        z-index: 3;

        @include respond-to('tablet-h') {
            padding-right: 60px;
            border-right: 1px solid #CECECE;
            
        }

        @include respond-to('laptop') {
            padding-right: 120px;
        }
    }

    &__topics {
        padding-top: 30px;
        padding-bottom: 20px;
        gap: 12px;
        flex-wrap: wrap;

        @include flex(row, center, center);

        @include respond-to('tablet-h') {
            gap: 8px;
            position: sticky;
            top: 132px;
            margin-top: 12px;
            margin-bottom: 0;
            @include flex(column, flex-start, stretch);
        }

        @include respond-to('laptop') {
            min-width: 180px;
        }
        
        li > a {
            display: flex;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2;
            color: $color_black;
            text-decoration: none;
        
            &.active, &:hover {
                font-weight: 700;
            }

            @include respond-to('tablet-h') {
                padding: 6px 0;
            }
        }

        li {
            &:not(:last-of-type) {
                padding-right: 10px;
                border-right: 1px solid $color_black;

                @include respond-to('tablet-h') {
                    padding: 0;
                    border-right: none;
                }
            }
        }
    }
}

.faq-topic {
    scroll-margin-top: 135px;

    &:not(&:last-of-type) {
        margin-bottom: 24px;


        @include respond-to('laptop') {
            margin-bottom: 66px;
        }
    }
    &__title {
        font-size: 24px;
        padding: 10px 0;
        top: 135px;
        background-color: $color_beige;

        @include respond-to('tablet-h') {
            position: sticky;
            font-size: 36px;
            padding: 20px 0;
            top: 130px;
            z-index: 3;
        }
    }
}

.faq-question {
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
    padding: 20px 0;

    &__title {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        line-height: 1.2;

        @include respond-to('tablet-h') {
            font-size: 18px;
        }

        svg {
            min-width: 24px;
        }
    }

    &__content {
        line-height: 1.4;
        margin-top: 16px;
    }
}