.form-camp-finder-section {
    padding: 30px 0;

    .container {
        @include flex(row, flex-start, stretch);
        gap: 2rem;
    }

    &__form-container {
        width: 40%;
    }

    &__card-container {
        padding: 1rem 0;
        max-height: 400px;
        overflow-y: auto;
        width: 100%;
        height: 100%;

        .region-card {
            margin-bottom: 2rem 0;
            border: 1px solid black;
            padding: 1rem 0;

            .card-body {
                @include flex(row, space-between, center);
                a {
                    cursor: pointer;
                }
            }
        }

        .card {
            margin-bottom: 1rem;
            max-width: 100%;
            padding: 1rem;

            &__not-found {
                text-align: center;
                background: yellow;
            }
        }

        .current-serving-areas {
            margin: 2rem 0;
        }
    }

    &__map-container {
        width: 60%;
    }

    &__map {
        height: 100%;
        width: 100%;
    }

    @include respond-to('tablet') {
        padding: 75px 0;

        &__map {
            // height: 500px;
        }
    }
}

.form-camp-finder {
    border-radius: 15px;
    border: 5px solid $color_white;
    background: $color_charcoal;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
    padding: 15px;
    gap: 18px;

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet') {
        border-radius: 20px;
        border: 10px solid $color_white;
        padding: 30px 50px;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    &__title {
        color: $color_white;
        text-align: center;
        font-size: 18px;
        font-weight: 800;
        line-height: 1.4;
    }

    &__form {
        gap: 10px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet') {
            

            @include flex(row, space-between, center);

            input {
                flex-grow: 1;
            }
        }
    }
}