.camp-menu-section {
    background-color: $color_charcoal;
    padding: 20px 0;
    position: sticky;
    top: 60px;
    z-index: 99;

    &__top-bar-active {
        top: 6rem;
    }

    @include respond-to('tablet-h') {
        top: 132px;

        &__top-bar-active {
            top: 10.5rem;
        }
    }
}

.camp-menu {
    gap: 50px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include flex(row, flex-start, center);

    @include respond-to('tablet') {
        @include flex(row, center, center);
    }

    @include respond-to('tablet-h') {
        gap: 90px;
        @include flex(row, center, center);
    }

    li {

        a{
            color: $color_white;
            font-weight: 700;
            line-height: 1.4;
            text-decoration: none;
            width: max-content;
            display: block;
        }
    }
}