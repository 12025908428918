.camp-slide {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    padding: 30px 40px 30px 30px;
    background-color: $color-beige;
    z-index: 9999;
    width: 100vw;
    transition: all .4s ease-in-out;

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.opened {
        right: 0;
    }

    @include respond-to('phone-h') {
        width: 90vw; 
    }

    @include respond-to('tablet-h') {
        width: 728px;
    }

    @include respond-to('desktop') {
        padding: 65px;
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        position: absolute;
        top: 2px;
        right: 0px;

        @include respond-to('tablet-h') {
            top: 8px;
            right: 8px;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9998;
        background-color: rgba($color_black, .2);
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;

        &.opened {
            visibility: visible;
            opacity: 1;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: stretch;
        gap: 30px;
    }

    &--big {
        @include respond-to('tablet') {
            width: 480px;
        }
        @include respond-to('tablet-h') {
            width: 960px;
            padding: 60px;
        }
        @include respond-to('laptop') {
            width: 1150px;
        }
    }

    ul, ol {
        line-height: 1.4;
        list-style: initial;
        padding-left: 20px;
    }

    strong {
        font-weight: 700;
    }
}