.featured-post {
    background-color: $color_yellow;
    gap: 30px;
    padding: 10px;
    border-radius: 20px;
    border: 5px solid $color_white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

    @include flex(column, flex-start, stretch);

    @include respond-to('tablet-h'){
        @include flex(row, space-between, stretch );
    }

    @include respond-to('desktop'){
        gap: 50px;
    }

    &__media {
        img {
            border-radius: 20px;
            display: block;
            object-fit: cover;

            @include respond-to('desktop'){
                height: 400px;
            }
        }

        @include respond-to('tablet-h'){
            min-width: 400px;
        }

        @include respond-to('tablet-h'){
            min-width: 600px;
        }

        @include respond-to('desktop'){
            min-width: 750px;
        }
    }

    &__body {
        gap: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        padding: 0 10px 10px ;

        @include respond-to('tablet-h'){
            padding: 30px 10px;
        }
    }

    &__meta {
        gap: 10px;
        
        @include flex(row, flex-start, center);
    }

    &__category {
        padding: 6px 12px;
        border-radius: 200px;
        border: 1px solid $color_black;
        text-decoration: none;
        color: $color_black;
        font-size: 12px;
        line-height: 1.5;
        background-color: $color_white;
    }

    &__reading {
        font-size: 14px;
        line-height: 1.5;
    }

    &__title {
        font-size: 24px;

        @include respond-to('tablet-h') {
            font-size: 36px;
        }
    }
}