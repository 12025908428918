.bg {
    &-beige {
        background-color: $color_beige;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.on-desktop {
    display: none !important;

    @include respond-to('tablet-h') {
        display: block !important;
    }
}

.on-mobile {
    @include respond-to('tablet-h') {
        display: none !important;
    }
}

.mt-title-text {
   margin-top: 10px; 
}

.mt-text-button {
    margin-top: 20px; 
 }

.text-center {
    text-align: center !important;
}

.image-rounded {
    border: 5px solid $color_white;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    @include respond-to('tablet-h') {
        border: 10px solid $color_white;
        max-width: 840px;
    }

    &.blue {
        border-color: $color_blue;
    }

    &.yellow {
        border-color: $color_yellow;
    }

    &.pink {
        border-color: $color_pink;
    }
}

.image-full {
    width: 100%;
}

.section {
    padding: 30px 0;
    overflow: hidden;

    @include respond-to('tablet-h') {
        padding: 75px 0;

        &--50 {
            padding: 50px 0;
        }
    }
}

.scroll-margin-top {
    scroll-margin-top: 65px;

    @include respond-to('tablet-h') {
        scroll-margin-top: 135px;
    }
}

.scroll-sticky {
    position: sticky;
    top: 60px;
    z-index: 99;

    @include respond-to('tablet-h') {
        top: 132px;
    }
}

.enriched-text {
    p, h1, h2, h3, h4, h5 {
        margin-bottom: 16px;
    }

    ul, ol {
        line-height: 1.4;
        list-style: inherit;
        padding-left: 20px;
    }

    ul {
        list-style: initial;
    }

    ol {
        list-style: number;
    }
}