%swiper-scrollbar {
    width: 100%;
    height: 5px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba($color_black, .1);
    border-radius: 5px;

    .swiper-scrollbar-drag {
        background-color: $color_blue;
        border-radius: 5px;
    }
}