.text-doodles {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 151px 0 60px;
    }

    &__container {
        gap: 20px;

        @include flex(column, flex-start, stretch);

        @include respond-to('tablet-h') {
            max-width: 860px;
            margin-left: auto;
            margin-right: auto;

            @include flex(row, center, stretch);
        }
    }

    &__left,
    &__right {
        @include respond-to('tablet-h') {
            max-width: 420px;
            position: relative;
        }
    }

    &__title {
        font-size: 2.25rem;
        line-height: 1.2;

        @include respond-to('tablet-h') {
            font-size: 3.5rem;
            line-height: 1;

            span {
                letter-spacing: -1.68px;
            }
        }
    }

    &__content {
        line-height: 1.4;
    }

    .btn {
        margin-top: 20px;
    }

    &__image-left {
        width: 132.88px;
        height: 140.75px;
        position: absolute;
        right: 49px;
        bottom: -6.75px;
    }

    &__image-right {
        width: 262.74px;
        height: 178.27px;
        margin-top: -80px;
        margin-left: -55px;
        margin-bottom: 10px;
    }

    &__image-left,
    &__image-right {
        display: none;
        object-fit: contain;

        @include respond-to('tablet-h') {
            display: block;
        }
    }
}