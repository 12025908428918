.saving-card {
    width: 100%;
    border-radius: 10px;
    border: 5px solid $color_blue_light;
    padding: 20px;
    background-color: $color_white;
    gap: 5px;
    text-align: left;

    @include flex(column, center, stretch);

    @include respond-to('tablet-h') {
        justify-content: flex-start;
    }

    &__week {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
    }

    &__price {
        font-size: 32px;
        font-weight: 800;
        line-height: 1.2;

        @include respond-to('tablet-h') {
            font-size: 36px;
        }
    }

    &__savings {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;

        @include respond-to('tablet-h') {
            font-size: 20px;
        }
    }

    &__total {
        font-size: 16px;
        line-height: 1.5;

        @include respond-to('tablet-h') {
            font-size: 20px;
        }
    }
}