.sticky-search {
  max-width: 100%;
  height: 0;
  transform: translateY(-1000px);

  &.show {
    height: auto;
    transform: translateY(0);
    transition: all 0.3s ease;
  }
}

.sticky-bar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 900;
  box-sizing: border-box;

  border-width: 0 4px 4px 4px;
  border-style: solid;
  border-color: #FFFFFF;

  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);

  .header & {
    &-container {
      padding-top: 10px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.location {
  &-section {
    display: flex;
    align-items: center;
  }

  &-pin {
    color: #e84c88;
    margin-right: 10px;
    font-size: 24px;
  }

  &-text {
    font-weight: 700;
    color: #000;
    font-size: 1rem;
  }
}

.search {
  &-section {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .form__field {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
    }

    input {
      background-color: #fff;
      border: 2px solid #0c161f;
      border-radius: 100px;
      color: #0c161f;
      font-size: 15px;
      font-weight: 400;
      padding: 12px 25px;
    }

    .btn {
      align-items: center;
      border: 2px solid #0c161f;
      border-radius: 100px;
      box-shadow: 2px 3px 0 0 #0c161f;
      color: #0c161f;
      cursor: pointer;
      display: inline-flex;
      font-size: 1rem;
      font-weight: 700;
      justify-content: center;
      min-height: 46px;
      padding: 12px 16px;
      text-decoration: none;
      transition: box-shadow 0.2s ease-in-out;
    }
  }

  &-input {
    padding: 10px 15px;
    border: 2px solid #e1e1e1;
    border-radius: 25px;
    font-size: 16px;
    width: 300px;
    margin-right: 10px;
  }

  &-button {
    background-color: white;
    color: #333;
    border: 2px solid #e1e1e1;
    border-radius: 25px;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .header div.sticky-bar-container {
    flex-direction: column;
    align-items: center;
  }

  .search {
    &-section {
      margin-top: 15px;
      margin-left: 0;
    }

    &-input {
      width: 70%;
    }
  }
}