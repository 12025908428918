.menu-mobile-toggle {
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;
    
    @include flex(row, center, center);

    &__close {
        display: none;
    }

    &.is-open {
        .menu-mobile-toggle__open {
            display: none;
        }

        .menu-mobile-toggle__close {
            display: flex;
        }
    }
}
.menu-mobile {
    background-color: $color_beige;
    position: absolute;
    height: calc( 100vh - 100px);
    top: 100px;
    left: -100%;
    width: 100%;
    padding: 30px 16px;
    transition: left .2s ease-in;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
    z-index: 9;

    &__landing-page {
        top: 4rem;
        height: calc( 100vh - 4rem);
    }

    &.is-open {
        left: 0;
    }

    ul > li > a {
        text-decoration: none;
        color: $color_black;
        font-size: 1rem;
        line-height: 1.5;
    }

    .main-menu {
        margin-bottom: 30px;
        gap: 20px;

        @include flex(column, flex-start, stretch);

        li {
            a {
                text-decoration: none;
                color: $color_black;
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &> ul {
                
                gap: 15px;

                @include flex(column, flex-start, stretch);

                > li {

                    a {
                        font-size: .875rem;
                        line-height: 1.5;
                        font-weight: 500;
                    }

                    &:first-of-type {
                        padding-top: 10px;
                    }
                }
            }
        }
    }

    .top-menu {
        margin-bottom: 30px;
        gap: 10px;

        @include flex(column, flex-start, stretch);

        li {
            a {
                font-size: .875rem;
                line-height: 1.5;
                font-weight: 700;
            }
        }
    }

    .buttons {
        gap: 16px;

        @include flex(row, space-between, center);

        .btn {
            width: calc((100% - 16px) / 2 );
        }
    }
}