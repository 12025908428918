html {
    font-size: 100%;
}

body {
    font-family: $font_general;
    font-weight: 500;
    font-size: .875rem;
    color: $color_black;

    @include respond-to('tablet-h') {
        font-size: 1rem;
    }
}

a {
    color: $color_blue;
}

p {
    font-weight: inherit;
    line-height: 1.4;

    strong {
        font-weight: 700;
    }
}

h1, h2, h3, h4, h5, h6,
[class*='heading'] {
    font-weight: 800;
}

[class*='heading'] span {
    font-family: $font_script;
    font-weight: 400;
}

.heading-script {
    font-family: $font_script;
    font-weight: 400;
}

.heading-highlight {
    &.blue span{
        color: $color_blue;
    }

    &.orange span{
        color: $color_orange;
    }

    &.green span{
        color: $color_green;
    }
}

.heading-1,
h1 {
    font-size: 2.25rem; //36px

    @include respond-to('tablet-h') {
        font-size: 3.5rem; //56px
    }
}

.heading-2,
h2 {
    font-size: 2rem; //32px

    @include respond-to('tablet-h') {
        font-size: 3rem; //48px
    }
}

.heading-3,
h3 {
    font-size: 1.25rem; //20px

    @include respond-to('tablet-h') {
        font-size: 1.5rem; //24px
    }
}

.heading-4,
h4 {
    font-size: 1.125rem; //18px

    @include respond-to('tablet-h') {
        font-size: 1.25rem; //20px
    }
}