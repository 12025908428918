.promo-banner {
    padding: 20px;
    background-color: $color_yellow;
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 30px;

    @include respond-to('tablet-h') {
        padding: 30px 40px;

        margin-top: 50px;
        margin-bottom: 50px;
    }

    &__title {
        font-size: 1.25rem;
        margin: 0;
        padding: 0 !important;
        line-height: 1.4;

        @include respond-to('tablet-h') {
            font-size: 2rem;
        }
    }

    p {
        margin-bottom: 0 !important;
    }

    .btn {
        margin-top: 20px;
        @include respond-to('tablet-h') {
            margin-top: 30px;
        }
    }
}