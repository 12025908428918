.big-title-cta-section {
    padding: 50px 0;

    @include respond-to('tablet-h') {
        padding: 75px 0;
    }
}

.big-title-cta {
    max-width: 635px;
    margin-left: auto;
    margin-right: auto;
}