// JUST USE THIS METHOD IF THE FONTS ARE NOT INCLUDED ON GOOGLE FONTS

@font-face {
    font-family: 'Switzer';
    src: url('./../fonts//Switzer-Regular.eot');
    src: url('./../fonts//Switzer-Regular.eot?#iefix') format('embedded-opentype'),
        url('./../fonts//Switzer-Regular.woff') format('woff'),
        url('./../fonts//Switzer-Regular.ttf') format('truetype'),
        url('./../fonts//Switzer-Regular.svg#Switzer-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Switzer';
    src: url('./../fonts//Switzer-Medium.eot');
    src: url('./../fonts//Switzer-Medium.eot?#iefix') format('embedded-opentype'),
        url('./../fonts//Switzer-Medium.woff') format('woff'),
        url('./../fonts//Switzer-Medium.ttf') format('truetype'),
        url('./../fonts//Switzer-Medium.svg#Switzer-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Switzer';
    src: url('./../fonts//Switzer-Bold.eot');
    src: url('./../fonts//Switzer-Bold.eot?#iefix') format('embedded-opentype'),
        url('./../fonts//Switzer-Bold.woff') format('woff'),
        url('./../fonts//Switzer-Bold.ttf') format('truetype'),
        url('./../fonts//Switzer-Bold.svg#Switzer-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Switzer';
    src: url('./../fonts//Switzer-Extrabold.eot');
    src: url('./../fonts//Switzer-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('./../fonts//Switzer-Extrabold.woff') format('woff'),
        url('./../fonts//Switzer-Extrabold.ttf') format('truetype'),
        url('./../fonts//Switzer-Extrabold.svg#Switzer-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kalam';
    src: url('./../fonts//Kalam-Bold.eot');
    src: url('./../fonts//Kalam-Bold.eot?#iefix') format('embedded-opentype'),
        url('./../fonts//Kalam-Bold.woff') format('woff'),
        url('./../fonts//Kalam-Bold.ttf') format('truetype'),
        url('./../fonts//Kalam-Bold.svg#Kalam-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}